import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.css';

import Card from 'react-bootstrap/Card';

import Localizer from './Localizer.js';
import Routes from './Routes.js';

import ScrollToTop from './Components/ScrollToTop.js';
import HeaderNav from './Components/HeaderNav.js';
import SnackBarToast from './Components/SnackBarToast.js';
import LeftNav from './Components/LeftNav.js';
import LoaderAnimation from './Components/LoaderAnimation.js';
import Footer from './Components/Footer.js';

import { setUserData, setCartData } from './redux/actions/index.js';
import { fetchHandler } from './scripts/fetchHandler.js';
import { isEmptyObject } from './scripts/utilities.js';
import * as CONFIG from './config.js';

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      isLeftNavOpen: true,
      snackBar: {
        isOpen: false,
        message: '',
        action: null,
        autoHide: 0
      }
    };

    this.left_nav_menu = React.createRef();
    this.loader_overlay = React.createRef();
    this.screen_overlay = React.createRef();

    this.resetUserCartData = this.resetUserCartData.bind(this);
    this.logout = this.logout.bind(this);
    this.checkAuth = this.checkAuth.bind(this);
    this.hideLoaderOverlay = this.hideLoaderOverlay.bind(this);
    this.showLoaderOverlay = this.showLoaderOverlay.bind(this);
    this.closeLeftNav = this.closeLeftNav.bind(this);
    this.openLeftNav = this.openLeftNav.bind(this);
    this.openSnackBar = this.openSnackBar.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
  }

  resetUserCartData() {
    this.props.setUserData({ isLoggedIn: false });
    this.props.setCartData({ isGet: false });
  }
  logout() {
    const self = this;
    fetchHandler(
      "POST",
      CONFIG.BACK_END_URL + "/logout",
      true
    )
      .then(getResponse => {
        if (getResponse.status === 204) self.resetUserCartData();
      });
  }
  checkAuth(force = false) {
    if (isEmptyObject(this.props.userData) || force) {
      const self = this;
      fetchHandler(
        "GET",
        CONFIG.BACK_END_URL + "/api/check-auth",
        true
      )
        .then(getResponse => {
          if (getResponse.status === 200) {
            getResponse.json()
              .then(jsonResponse => {
                const custom_field = JSON.parse(jsonResponse.custom_field);
                self.props.setUserData(
                  {
                    isLoggedIn: true,
                    name: jsonResponse.firstname + " " + jsonResponse.lastname,
                    image: custom_field["1"],
                    status: jsonResponse.status
                  }
                );
              });
          }
          else {
            fetch(CONFIG.BACK_END_URL + "/sanctum/csrf-cookie", {
              method: 'GET',
              mode: 'cors',
              credentials: 'include'
            })
              .then(getSanctumResponse => {
                if (getSanctumResponse.status === 204)
                  return Promise.reject(new Error("User - " + getResponse.status));
                else
                  return Promise.reject(new Error("Sanctum - " + getSanctumResponse.status));
              })
              .catch(errSanc => {
                self.resetUserCartData();
              });
          }
        })
        .catch(err => {
          self.resetUserCartData();
        });
    }
  }

  hideLoaderOverlay() {
    this.loader_overlay.current.style.display = "none";
  }
  showLoaderOverlay(widthVal, widthUnit) {
    if (typeof widthVal !== "undefined" && widthVal !== undefined && widthVal !== null && typeof widthUnit !== "undefined" && widthUnit !== undefined && widthUnit !== null)
      this.loader_overlay.current.style.width = widthVal + widthUnit;
    this.loader_overlay.current.style.display = "block";
  }

  closeLeftNav() {
    if (this.state.isLeftNavOpen) {
      this.setState({ isLeftNavOpen: false }, () => {
        const panelWidth = Math.ceil(this.left_nav_menu.current.offsetWidth);

        this.left_nav_menu.current.style.left = "-" + panelWidth + "px";
        this.screen_overlay.current.style.display = "none";
        this.screen_overlay.current.removeEventListener("click", this.closeLeftNav);
        this.hideLoaderOverlay();
        //loadLeftNavBack(0);
      });
    }
  }
  openLeftNav() {
    if (!this.state.isLeftNavOpen) {
      this.setState({ isLeftNavOpen: true }, () => {

        this.left_nav_menu.current.style.left = 0;
        this.screen_overlay.current.style.display = "block";
        this.screen_overlay.current.addEventListener("click", this.closeLeftNav);
      });
    }
  }

  openSnackBar(message, action, autoHide) {
    this.setState(() => {
      return {
        snackBar: {
          isOpen: true,
          message: message,
          action: action,
          autoHide: parseInt(autoHide)
        }
      };
    });
  }
  closeSnackBar(evt, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(() => {
      return {
        snackBar: {
          isOpen: false,
          message: '',
          action: null,
          autoHide: 0
        }
      };
    });
  }

  componentDidMount() {
    this.checkAuth();
  }
  render() {
    if (isEmptyObject(this.props.userData)) return (<>Loading...</>);
    else {
      const supportsHistory = 'pushState' in window.history;
      return (
        <>
          <Router forceRefresh={!supportsHistory}>
            <Localizer>
              <ScrollToTop />
              <div className="wrapper">
                <div className="contents">
                  <HeaderNav
                    openLeftNav={this.openLeftNav}
                    logout={this.logout}
                  />
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes checkAuth={this.checkAuth} openSnackBar={this.openSnackBar} />
                  </Suspense>
                  <SnackBarToast {...this.state.snackBar} closeSnackBar={this.closeSnackBar} />
                </div>
              </div>
              <Card ref={this.left_nav_menu} className="card-primary panel_left_nav_menu">
                <LeftNav
                  isOpen={this.state.isLeftNavOpen}
                  closeLeftNav={this.closeLeftNav}
                  hideLoaderOverlay={this.hideLoaderOverlay}
                  showLoaderOverlay={this.showLoaderOverlay}
                  logout={this.logout}
                />
              </Card>
              <Footer />
            </Localizer>
          </Router>
          <div ref={this.loader_overlay} className="loader_overlay">
            <LoaderAnimation />
          </div>
          <div ref={this.screen_overlay} className="screen_overlay" />
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return { userData: state.rootReducer.userData };
};
const mapDispatchToProps = dispatch => {
  return {
    setUserData: rootData => dispatch(setUserData(rootData)),
    setCartData: cartData => dispatch(setCartData(cartData))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);