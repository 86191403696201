export const id_id = {
  id: 2,
  lang: "id",
  code: "id-id",
  pluralSuffix: "",
  motto: "Bahasa Indonesia",
  /* */
  aboutUsText: "Tentang Kami",
  allText: "Semua",
  backText: "Kembali",
  backToAccountText: "Kembali ke Halaman Akun",
  backToHomeText: "Kembali ke Halaman Utama",
  backToTopText: "Kembali ke Atas",
  bankText: "Bank",
  birthdayText: "Tanggal Lahir",
  brandText: "Merek",
  browseText: "Pilih",
  cancelText: "Batal",
  categoryText: "Kategori",
  closeText: "Tutup",
  codeText: "Kode",
  commentText: "Komentar",
  confirmNewPasswordText: "Konfirmasi Kata Sandi Baru",
  confirmPasswordText: "Konfirmasi Kata Sandi",
  dayText: "hari",
  defaultText: "Utama",
  deleteText: "Hapus",
  destinationText: "Tujuan",
  editText: "Ubah",
  emailText: "Email",
  emailVerificationText: "Verifikasi Email",
  failedText: "Gagal",
  femaleText: "Perempuan",
  filterText: "Saring",
  fullNameText: "Nama Lengkap",
  helpText: "Bantuan",
  itemText: "Barang",
  infoText: "Info",
  informationText: "Informasi",
  languageText: "Bahasa",
  maleText: "Laki-laki",
  maxText: "Maks.",
  minText: "Min.",
  methodText: "Metode",
  newPasswordText: "Kata Sandi Baru",
  newsletterText: "Surat Kabar",
  notesText: "Catatan",
  organizationText: "Organisasi",
  storeText: "Gerai",
  passwordText: "Kata Sandi",
  payText: "Bayar",
  paymentText: "Pembayaran",
  pendingText: "Menunggu",
  pleaseWaitText: "Tunggu sebentar",
  priceText: "Harga",
  profileText: "Profil",
  phoneNumberText: "No. Telepon",
  qtyText: "Jmlh",
  quantityText: "Jumlah",
  reasonText: "Alasan",
  resendEmailVerificationText: "Kirim Ulang Email Verifikasi",
  saveText: "Simpan",
  savingText: "Menyimpan",
  searchProductText: "Cari Produk",
  selectText: "Pilih",
  sendText: "Kirim",
  sendingText: "Mengirim",
  sexText: "Jenis Kelamin",
  shareText: "Bagikan",
  shipperText: "Pengirim",
  sortText: "Urutkan",
  statusText: "Status",
  successText: "Sukses",
  timeText: "Waktu",
  totalText: "Total",
  trackText: "Lacak",
  trackingText: "Melacak",
  warningText: "Perhatian",
  /* */
  month01: "Januari",
  month02: "Februari",
  month03: "Maret",
  month04: "April",
  month05: "Mei",
  month06: "Juni",
  month07: "Juli",
  month08: "Agustus",
  month09: "September",
  month10: "Oktober",
  month11: "November",
  month12: "Desember",
  monthShort01: "Jan",
  monthShort02: "Feb",
  monthShort03: "Mar",
  monthShort04: "Apr",
  monthShort05: "Mei",
  monthShort06: "Jun",
  monthShort07: "Jul",
  monthShort08: "Ags",
  monthShort09: "Sep",
  monthShort10: "Okt",
  monthShort11: "Nov",
  monthShort12: "Des",
  addresses: "Daftar Alamat",
  changeEmail: "Ubah Email",
  changePassword: "Perbarui Kata Sandi",
  profilePicture: "Foto Profil",
  selectProfilePicture: "Pilih Foto Profil",
  removeProfilePicture: "Hapus Foto Profil",
  noBrand: "Tidak Bermerek",
  sortRelevance: "Relevansi",
  sortLatest: "Terbaru",
  sortNameAsc: "Nama Menaik",
  sortNameDesc: "Nama Menurun",
  sortPriceAsc: "Harga Menaik",
  sortPriceDesc: "Harga Menurun",
  menuCategory: "Semua Kategori",
  menuFavourite: "Produk Disukai",
  newsletterLabel: "Saya ingin menerima kabar mengenai penawaran dan promosi terbaru dari {store_name} ke email Saya.",
  tncLabel1: "Dengan men-centang kotak persetujuan ini, dan atau menekan tombol",
  tncLabel2: "berarti Anda menyatakan setuju dengan",
  securedBy: "Aman Dengan",
  /* */
  errorText: "Terjadi Kesalahan",
  errorGeneral: "Ada sesuatu yang tidak beres, muat ulang halaman dan coba lagi. Hubungi kami apabila masalah tetap muncul.",
  errFillEmail: "Harap Isi",
  errFillGreCaptcha: "Buktikan Anda Bukan Robot",
  errPasswordConfirmationNotMatch: "Konfirmasi Kata Sandi Tidak Sama",
  errWrongCaptcha: "Terjadi Kesalahan Pada Captcha",
  errAccountNotVerified: "Akun Anda Belum Terverifikasi. Periksa Email Anda, dan klik tautan di dalam email verifikasi.",
  errCheckAgreement: "Harap Centang Kotak Setuju Syarat & Ketentuan",
  errSelectLanguage: "Harap Pilih Bahasa",
  errInvalidInputData: "Terjadi kesalahan pada daata yang Anda masukkan. Mohon periksa kembali.",
  errStillSubmiting: "Mohon menunggu proses sebelumnya masih berjalan.",
  errWaitCountDown: "Mohon menunggu waktu hitung mundur selesai sebelum dapat mencoba lagi.",
  errInvalidLink: "Tautan yang Anda gunakan, Salah! Pastikan Anda menggunakan tautan yang Kami kirimkan ke email Anda.",
  errAddFave: "Gagal favoritkan produk.",
  formShowPasswordButton: "Lihat Kata Sandi",
  formHidePasswordButton: "Sembunyikan Kata Sandi",
  formPasswordDescribe: "Gunakan 8 karakter atau lebih dengan minimal 1 angka, 1 huruf kapital dan 1 huruf kecil.",
  formTelephoneDescribe: "Format: +kodeNegara-nomor",
  formTelephoneExampleDescribe: "Contoh (ID): +62-81234567890",
  loginBeforeFave: "Harap masuk sebelum favoritkan produk.",
  /* CONTACT US */
  contactUsText: "Hubungi Kami",
  liveChatText: "Live Chat",
  needHelp: "Butuh Bantuan Kami?",
  gladToHelp: "Kami senang membantu Anda, jangan sungkan untuk menghubungi Kami.",
  suggestLiveChat1: "Apakah Anda tahu kita memiliki layanan",
  suggestLiveChat2: "Bicara langsung dengan Kami melalui Live Chat, tombol ada di pojok kanan bawah.",
  checkHelp: "Silahkan lihat halaman Bantuan untuk solusi yang lebih cepat mengenai pertanyaan umum.",
  /*CART*/
  cartTextShort: "Keranjang",
  cartTextLong: "Keranjang Belanja",
  cartRemove: "Keluarkan dari keranjang",
  subTotalText: "Sub-total",
  shippingNotYetIncludedText: "Harga belum termasuk pajak dan ongkos kirim.",
  noItemInCart: "Belum ada barang di keranjang belanja.",
  refreshCartQty: "Perbarui Jumlah",
  /* CHECKOUT */
  checkoutText: "Pembayaran",
  proceedCheckOutText: "Proses Pembayaran",
  shippingText: "Pengiriman",
  shippingServices: "Layanan Pengiriman",
  shippingSubtitle: "Detil Pengiriman",
  billingAddress: "Alamat Penagihan",
  shippingAddress: "Alamat Pengiriman",
  selectService: "pilih jenis layanan",
  errorNoAddress: "Tidak ada alamat. Anda harus menambahkan alamat sebelum dapat melakukan pembayaran.",
  checkoutVoucherSubtitle: "Punya Voucher? Masukan kode voucher di sini",
  useVoucher: "Gunakan Voucher",
  cancelVoucher: "Batalkan Voucher",
  orderSummary: "Ringkasan Transaksi",
  totalWeight: "Berat Total",
  shippingCost: "Ongkos Kirim",
  insuranceText: "Asuransi",
  voucherCodeNotFound: "Kode Voucher Tidak Ditemukan",
  selectAddressShippingForVoucher: "Harap pilih alamat pengiriman dan layanan pengiriman sebelum dapat menggunakan voucher ini",
  exceedVoucherUse: "Maaf, voucher ini sudah habis terpakai",
  exceedVoucherUserUse: "Anda sudah mencapai batas kuota penggunaan voucher ini",
  noDiscountForCart: "Tidak ada potongan harga untuk barang-barang dikeranjang menggunakan voucher ini",
  errSelectBillingAddress: "Harap Pilih Alamat Penagihan",
  errSelectShippingAddress: "Harap Pilih Alamat Pengiriman",
  errSelectShipping: "Harap Pilih Layanan Pengiriman",
  paymentSuccess: "Pembayaran Berhasil",
  paymentPending: "Menunggu Pembayaran",
  paymentFailed: "Pembayaran Gagal",
  thankYouforShoppingwithUs: "Terima kasih telah berbelanja bersama kami.",
  banktransferVirtualaccount: "Bank Transfer Virtual Account",
  yourOrderId: "ID transaksi pembelian Anda",
  immidiatelyMakepayment: "Segera lakukan pembayaran ke",
  howToPayHadBeenSent: "Detil cara pembayaran telah Kami kirimkan ke email Anda.",
  orderWillAutomaticallyCanceled: "Transaksi ini akan otomatis dibatalkan oleh sistem apabila dalam kurun waktu 24 jam kedepan pembayaran tidak dilakukan.",
  seeOrderDetails: "Lihat Detil Pembelian",
  shopAgain: "Kembali Berbelanja",
  paymentHadBeenProcessed: "Pembayaran Anda berhasil diproses.",
  paymentWaitingApproval: "Pembayaran Anda ditandai oleh FDS. Tunggu persetujuan dari Admin.",
  errCancelSnap: "Halaman pembayaran snap ditutup/dibatalkan.",
  sorryPaymentFailed: "Maaf proses pembayaran Anda gagal.",
  tryAgainFurtherSupport: "Silahkan coba kembali, jangan sungkan untuk mengubungi Kami untuk bantuan lebih lanjut.",
  /*LOGIN*/
  loginText: "Masuk",
  logoutText: "Keluar",
  loginFormCardSubTitle: "Masuk menggungakan email dan kata sandi yang pernah didaftarkan.",
  loginFormRememberMeText: "Ingat Saya",
  loginFormForgotPasswordText: "Lupa Kata Sandi",
  loginFormForgotPasswordCardSubTitle: "Masukan email yang Anda daftarkan.",
  loginFormResetPasswordText: "Buat Ulang Kata Sandi",
  loginFormResetPasswordCardSubTitle: "Masukkan Kata Sandi Baru.",
  loginNewCustomerText: "Pelanggan Baru",
  loginNewCustomerCardSubTitle: "Daftar Akun",
  loginNewCustomerCardBody: "Dengan membuat akun, Anda dapat berbelanja dengan lebih cepat, mudah, dan nyaman. Lewat akun Anda, Anda dapat memantau update status pembelian Anda.",
  /* ACCOUNT */
  accountText: "Akun",
  accountMenu: "Menu Akun",
  myAccountText: "Akun Saya",
  addressRegistered: "alamat terdaftar",
  addressRegisteredPlural: "alamat terdafatar",
  noAddressRegistered: "Tidak ada alamat.",
  addAddressText: "Tambah Alamat",
  /* ADDRESS */
  addressText: "Alamat",
  editAddressText: "Edit Alamat",
  streetAddress: "Alamat Baris 1",
  streetAddress2: "Alamat Baris 2 (misal: RT/RW, Kelurahan)",
  stateProvince: "Propinsi",
  cityText: "Kabupaten/Kota",
  districtText: "Kecamatan",
  postZipCode: "Kode Pos",
  errSelectZone: "Harap Pilih Propinsi",
  errSelectCity: "Harap Pilih Kabupaten/Kota",
  errSelectDistrict: "Harap Pilih Kecamatan",
  setDefaultAddress: "Jadikan Alamat Utama",
  successAddAddress: "Alamat baru berhasil ditambahkan.",
  successEditAddress: "Alamat berhasil diperbarui.",
  successDeleteAddress: "Alamat berhasil dihapus.",
  /* REGISTER */
  registerText: "Daftar",
  registerThankYou: "Terima Kasih telah mendaftar menjadi pelanggan {store_name}!",
  registerVerifyInfo: "Sebelum Anda dapat masuk dan mengakses akun yang baru saja Anda daftarkan, mohon klik tautan verifikasi yang Kami kirimkan ke Email Anda.",
  loginDetails: "Detil",
  verifyEmailSuccess1: "Selamat! Alamat Email Anda berhasil diverifikasi. Sekarang Anda sudah bisa menggunakan akun ini untuk berbelanja dengan semua kemudahan dan fitur untuk pelanggan.",
  verifyEmailSuccess2: "Silahkan menambahkan data alamat untuk memudahkan Anda ketika ingin melakukan pembayaran.",
  verifyEmailFailed1: "Maaf, ada kesalahan dalam proses Verifikasi Akun.",
  verifyEmailFailed2: "Pastikan Anda menggunakan tautan yang Kami kirimkan ke Email Anda.",
  verifyEmailFailed3: "Apabila tidak menemukan Email dari Kami, mohon periksa juga folder spam, atau dapat melakukan Kirim Ulang di halaman Akun Anda.",
  verifyEmailFailed4: "Jangan sungkan untuk menghubungi Kami apabila masih menemukan masalah.",
  loginToVerify: "Anda harus masuk untuk Verifikasi Email.",
  resendVerifyEmailSuccess: "Email Verifikasi berhasil dikirim ulang.",
  resendVerifyEmailIsVerified: "Email Anda sudah ter-verifikasi.",
  /* SETTING */
  settingText: "Pengaturan",
  accountSetting: "Pengaturan Akun",
  chooseLanguage: "Pilih Bahasa",
  profilePictureDescribe1: "Hanya menerima berkas gambar dengan tipe JPEG / PNG file, dengan besar tidak melebihi 2 MB.",
  profilePictureDescribe2: "Panjang dan lebar minimal gambar adalah 228px.",
  invalidPictureSelected: "File Gambar yang Dipilih Bermasalah",
  errDimensions: "Resolusi berkas gambar tidak sesuai.",
  errMime: "Gambar harus berupa berkas dengan tipe jpeg atau png.",
  errMaxFile: "Ukuran berkas gambar tidak boleh lebih dari 2 MB.",
  successEditProfile: "Pengaturan profil berhasil diperbarui.",
  removeAddressConfirm: "Yakin ingin menghapus alamat ini?",
  /* CHANGE PASSWORD */
  changePasswordDescribe: "Masukan kata sandi untuk otentikasi.",
  errSimilarPassword: "Kata sandi yang baru tidak boleh sama dengan yang sekarang.",
  successChangePassword: "Kata sandi berhasil diubah!",
  /* ORDER */
  orderText: "Pembelian",
  orderList: "Daftar Transaksi",
  orderId: "ID Transaksi",
  dateFrom: "Tanggal: Dari",
  dateTo: "Tanggal: Sampai",
  noOrderFound: "Pembelian tidak ditemukan.",
  orderDetails: "Detil Pembelian",
  dateAdded: "Tanggal Dibuat",
  orderHistory: "Riwayat Transaksi",
  airwaybill: "No. Resi",
  trackingResult: "Hasil Pelacakan",
  errorTracking: "No. resi tidak terlacak. Silahkan coba lacak no. resi langsung di website kurir bersangkutan.",
  shipmentStatus: "Status Pengiriman",
  shipmentDate: "Tanggal Pengiriman",
  noShipmentHistory: "Belum ada riwayat pengiriman.",
  confirmPackageReceived: "Konfirmasi Terima Barang",
  orderFinishConfirm: "Apakah Anda yakin telah menerima barang pembelian anda dengan sesuai dan dalam keadaan yang baik?",
  orderFinishThankYou: "Selamat pembelian telah selesai. Terima kasih telah berbelanja bersama Kami!",
  paymentDetails: "Detil Pembayaran",
  vaNumbers: "Nomor Virtual Account",
  mailInvoice: "Kirim Invoice ke Email",
  successMailInvoice: "Invoice berhasil dikirim ke email Anda.",
  /* RETURN */
  returnText: "Pengembalian",
  returnBtnTooltip: "Ajukan Pengembalian Produk Ini",
  /* VOUCHER */
  voucherText: "Voucher",
  voucherCode: "Kode Voucher",
  copyCodeTextTooltip: "Salin Kode",
  /* PRODUCT */
  productText: "Produk",
  allProduct: "Semua Produk",
  productAddFavourite: "Favoritkan Produk",
  productRemoveFavourite: "Keluarkan Produk dari Favorit",
  productShare: "Bagikan Produk",
  noProductFound: "Tidak ada produk ditemukan.",
  addToCart: "Masukan Keranjang",
  toFavourite: "Favoritkan",
  isFavourite: "Favorit",
  productCode: "Kode Produk",
  stockText: "Stok",
  minOrder: "Pembelian Minimum",
  descriptionText: "Deskripsi",
  specificationText: "Spesifikasi",
  reviewText: "Ulasan",
  writeReview: "Tulis Ulasan",
  ratingText: "Nilai",
  noReview: "Belum ada ulasan.",
  errGiveRating: "Harap berikan penilaian dengan mengetuk tombol bintang.",
  errFillReview: "Harap tuliskan ulasan Anda.",
  successReviewSubmitted: "Ulasan berhasil dikirim dan menunggu persetujuan admin.",
  volumeDiscount: "Beli Banyak Lebih Untung",
  loginBeforeAddToCart: "Harap masuk sebelum masukan ke keranjang.",
  errQtyEmpty: "Jumlah produk tidak boleh kosong",
  errQtyMin: "Jumlah produk minimal kurang dari batas minimum",
  errQtyMax: "Jumlah produk melebihi batas stok",
  errQtyMax100: "Jumlah produk maximal 100",
  pleaseChoose: "Harap pilih",
  successAddToCart: "Produk ini berhasil dimasukan ke keranjang.",
  relatedProduct: "Produk Terkait",
  /* FAVOURITE */
  favouriteText: "Produk Favorit",
  /* FOOTER */
  footerPoint1: "Pembayaran Mudah",
  footerPoint1Desc: "Berbagai macam pilihan untuk pembayaran. Untuk pembayaran dengan kartu kredit, dapat menghubungi kami via chat.",
  footerPoint2: "Harga Spesial",
  footerPoint2Desc: "Untuk mendapatkan harga khusus, silakan menghubungi kami via email.",
  footerPoint3: "Produk Terbaik",
  footerPoint3Desc: "Produk produk pilihan terbaik dengan inovasi terbaru.",
  /* 404 */
  pageNotFound: "Halaman Tidak Ditemukan",
  requestedPageNotFound: "Halaman tidak ditemukan. Halaman mungkin sudah tidak ada, atau ada kesalahan pada URL."
};