export const en_gb = {
  id: 1,
  lang: "en",
  code: "en-gb",
  pluralSuffix: "s",
  motto: "English Languange",
  /* */
  aboutUsText: "About Us",
  allText: "All",
  backText: "Back",
  backToAccountText: "Back to Account Page",
  backToHomeText: "Back to Home Page",
  backToTopText: "Back to Top",
  bankText: "Bank",
  birthdayText: "Birthday",
  brandText: "Brand",
  browseText: "Browse",
  cancelText: "Cancel",
  categoryText: "Category",
  closeText: "Close",
  codeText: "Code",
  commentText: "Comment",
  confirmNewPasswordText: "Confirm New Password",
  confirmPasswordText: "Confirm Password",
  dayText: "day",
  defaultText: "Default",
  deleteText: "Delete",
  destinationText: "Destination",
  editText: "Edit",
  emailText: "Email",
  emailVerificationText: "Email Verification",
  failedText: "Failed",
  femaleText: "Female",
  filterText: "Filter",
  fullNameText: "Full Name",
  helpText: "Help",
  itemText: "Item",
  infoText: "Info",
  informationText: "Information",
  languageText: "Language",
  maleText: "Male",
  maxText: "Max",
  minText: "Min",
  methodText: "Method",
  newPasswordText: "New Password",
  newsletterText: "Newsletter",
  notesText: "Notes",
  organizationText: "Organization",
  storeText: "Store",
  passwordText: "Password",
  payText: "Pay",
  paymentText: "Payment",
  pendingText: "Pending",
  phoneNumberText: "Phone Number",
  pleaseWaitText: "Please wait",
  priceText: "Price",
  profileText: "Profile",
  qtyText: "Qty",
  quantityText: "Quantity",
  reasonText: "Reason",
  resendEmailVerificationText: "Resend Email Verification", 
  saveText: "Save",
  savingText: "Saving",
  searchProductText: "Product Search",
  selectText: "Select",
  sendText: "Send",
  sendingText: "Sending",
  sexText: "Sex",
  shareText: "Share",
  shipperText: "Shipper",
  sortText: "Sort",
  statusText: "Status",
  successText: "Success",
  timeText: "Time",
  totalText: "Total",
  trackText: "Track",
  trackingText: "Tracking",
  warningText: "Warning",
  /* */
  month01: "January",
  month02: "February",
  month03: "March",
  month04: "April",
  month05: "May",
  month06: "June",
  month07: "July",
  month08: "August",
  month09: "September",
  month10: "October",
  month11: "November",
  month12: "December",
  monthShort01: "Jan",
  monthShort02: "Feb",
  monthShort03: "Mar",
  monthShort04: "Apr",
  monthShort05: "May",
  monthShort06: "Jun",
  monthShort07: "Jul",
  monthShort08: "Aug",
  monthShort09: "Sep",
  monthShort10: "Oct",
  monthShort11: "Nov",
  monthShort12: "Dec",
  addresses: "Addresses",
  changeEmail: "Change Email",
  changePassword: "Change Password",
  profilePicture: "Profile Picture",
  selectProfilePicture: "Select Profile Picture",
  removeProfilePicture: "Remove Picture",
  noBrand: "No Brand",
  sortRelevance: "Relevance",
  sortLatest: "Latest",
  sortNameAsc: "Name Ascending",
  sortNameDesc: "Name Descending",
  sortPriceAsc: "Price Ascending",
  sortPriceDesc: "Price Descending",
  menuCategory: "Categories",
  menuFavourite: "Favourite",
  newsletterLabel: "I want to receive newsletter from {store_name} to my Email about new offer and promotion.",
  tncLabel1: "By checking this box, and or pressing the",
  tncLabel2: "button, means that You have agreed with our",
  securedBy: "Secured By",
  /* */
  errorText: "Error",
  errorGeneral: "Something wrong, please refresh page and try again. Contact us if problem persist.",
  errFill: "Please Enter",
  errFillGreCaptcha: "Prove You're Not a Robot",
  errPasswordConfirmationNotMatch: "Password Confirmation did Not Match",
  errWrongCaptcha: "Captcha Validation Error",
  errAccountNotVerified: "Your Account Is Not Verified. Please check Your Email for email verification and click the link inside to verify.",
  errCheckAgreement: "Please Check The Terms & Condition Agreement",
  errSelectLanguage: "Please Select Langugage",
  errInvalidInputData: "Invalid Input Data. Please re-check Your Input.",
  errStillSubmiting: "Please wait, the previous submission still in progress.",
  errWaitCountDown: "Please wait for the count down timer to finish before You can try again.",
  errInvalidLink: "Invalid Link! Make sure You are using the link that we've sent to Your email.",
  errAddFave: "Error adding to favourite.",
  formShowPasswordButton: "Show Password",
  formHidePasswordButton: "Hide Password",
  formPasswordDescribe: "Eight or more characters, with at least one number, one lowercase and one uppercase letter.",
  formTelephoneDescribe: "Format: +countryCode-number",
  formTelephoneExampleDescribe: "Example (US): +1-23456789",
  loginBeforeFave: "Please Login before adding to favourite.",
  /* CONTACT US */
  contactUsText: "Contact Us",
  liveChatText: "Live Chat",
  needHelp: "Need Our Support?",
  gladToHelp: "We're glad to help You, feel free to contact us.",
  suggestLiveChat1: "Do You know that we have",
  suggestLiveChat2: "Talk to us directly through Live Chat, button is on the bottom right corner.",
  checkHelp: "Check our Help page for faster solution of common problems.",
  /*CART*/
  cartTextShort: "Cart",
  cartTextLong: "Shopping Cart",
  cartRemove: "Remove from cart",
  subTotalText: "Sub-total",
  shippingNotYetIncludedText: "Tax and shipping cost yet to be included.",
  noItemInCart: "No item in shopping cart.",
  refreshCartQty: "Update Quantity",
  /* CHECKOUT */
  checkoutText: "Checkout",
  proceedCheckOutText: "Proceed to Checkout",
  shippingText: "Shipping",
  shippingServices: "Shipping Service",
  shippingSubtitle: "Shipping Details",
  billingAddress: "Billing Address",
  shippingAddress: "Shipping Address",
  selectService: "select service",
  errorNoAddress: "No address found. In order to checkout You need to have an address.",
  checkoutVoucherSubtitle: "Enter voucher code",
  useVoucher: "Use Voucher",
  cancelVoucher: "Cancel Voucher",
  orderSummary: "Order Summary",
  totalWeight: "Total Weight",
  shippingCost: "Shipping Cost",
  insuranceText: "Insurance",
  voucherCodeNotFound: "Voucher Code Not Found",
  selectAddressShippingForVoucher: "Please select address and shipping services before using this voucher",
  exceedVoucherUse: "Sorry, this voucher has been used up",
  exceedVoucherUserUse: "You have reach the maximum limit of using this voucher",
  noDiscountForCart: "No discount for the items in cart with this voucher",
  errSelectBillingAddress: "Please Select Billing Address",
  errSelectShippingAddress: "Please Select Shipping Address",
  errSelectShipping: "Please Select Shipping Service",
  paymentSuccess: "Payment Success",
  paymentPending: "Payment Pending",
  paymentFailed: "Payment Failed",
  thankYouforShoppingwithUs: "Thank You for shopping with us.",
  banktransferVirtualaccount: "Bank Transfer Virtual Account",
  yourOrderId: "Your Order ID",
  immidiatelyMakepayment: "Please immidiately make payment to",
  howToPayHadBeenSent: "Details on how to complete Your payment had been sent to Your email.",
  orderWillAutomaticallyCanceled: "This order transaction will automatically canceled within 24 hours if You don't make the payment.",
  seeOrderDetails: "See Order Details",
  shopAgain: "Shop Again",
  paymentHadBeenProcessed: "Your Payment Had Been Processed.",
  paymentWaitingApproval: "Your payment is challenge by FDS. Awaiting for Admin approval.",
  errCancelSnap: "Cancel from snap close.",
  sorryPaymentFailed: "We're sorry Your payment is failed / canceled.",
  tryAgainFurtherSupport: "Please try again, and feel free to contact us for further support.",
  /*LOGIN*/
  loginText: "Login",
  logoutText: "Logout",
  loginFormCardSubTitle: "Login using registered email and password.",
  loginFormRememberMeText: "Remember Me",
  loginFormForgotPasswordText: "Forgot Password",
  loginFormForgotPasswordCardSubTitle: "Enter Your registered email.",
  loginFormResetPasswordText: "Reset Password",
  loginFormResetPasswordCardSubTitle: "Enter New Password.",
  loginNewCustomerText: "New Customer",
  loginNewCustomerCardSubTitle: "Register an Account",
  loginNewCustomerCardBody: "By registering an account Your shopping expirience will be much faster and convenient. Easily track Your order status from account dashboard.",
  /* ACCOUNT */
  accountText: "Account",
  accountMenu: "Account Menu",
  myAccountText: "My Account",
  addressRegistered: "address registered",
  addressRegisteredPlural: "addresses registered",
  noAddressRegistered: "No address registered.",
  addAddressText: "Add Address",
  /* ADDRESS */
  addressText: "Address",
  editAddressText: "Edit Address",
  streetAddress: "Address Line 1",
  streetAddress2: "Address Line 2 (i.e. Sub-district)",
  stateProvince: "State/Province",
  cityText: "City",
  districtText: "District",
  postZipCode: "ZIP/Post Code",
  setDefaultAddress: "Set as Default Address",
  errSelectZone: "Please Select State/Province",
  errSelectCity:  "Please Select City",
  errSelectDistrict: "Please Select District",
  successAddAddress: "New Address added successfully.",
  successEditAddress: "Address has been edited.",
  successDeleteAddress: "Address has been deleted.",
  /* REGISTER */
  registerText: "Register",
  registerThankYou: "Thank You for registering as {store_name}'s customer!",
  registerVerifyInfo: "Before You can access the newly created account, You need to click the verification link that we've sent to Your Email Address.",
  loginDetails: "Login Details",
  verifyEmailSuccess1: "Congratulation! Your Email Address successfully verified. Now You may use this account to shop with all the features and convinience that comes wiht it.",
  verifyEmailSuccess2: "Please add Your address for faster checkout.",
  verifyEmailFailed1: "Sorry, there is an error while we verified Your Email.",
  verifyEmailFailed2: "Make sure You are using the link that we sent to Your Email.",
  verifyEmailFailed3: "If You can't find the Verification Email, please check the spam folder, or You can click for resend Email Verification in Your Account Page",
  verifyEmailFailed4: "Feel free to contact us if the problem persist.",
  loginToVerify: "You need to be logged in to Verify Email.",
  resendVerifyEmailSuccess: "Successfully Resend Email Verification.",
  resendVerifyEmailIsVerified: "Your Email is Verified.",
  /* SETTING */
  settingText: "Setting",
  accountSetting: "Account Setting",
  chooseLanguage: "Choose Language",
  profilePictureDescribe1: "Only accept JPEG / PNG file, with maximum size of 2 MB.",
  profilePictureDescribe2: "Minimum picture width and height is 228px.",
  invalidPictureSelected: "Invalid File Picture Selected",
  errDimensions: "The picture file has invalid image dimensions.",
  errMime: "The picture file must be a file of jpeg or png.",
  errMaxFile: "The picture size may not be greater than 2 MB.",
  successEditProfile: "Successfully update profile setting.",
  removeAddressConfirm: "Are You sure want to delete this address?",
  /* CHANGE PASSWORD */
  changePasswordDescribe: "Input current password to authenticate this action.",
  errSimilarPassword: "New password can't be the same with the current password.",
  successChangePassword: "Your password has been changed!",
  /* ORDER */
  orderText: "Order",
  orderList: "Order List",
  orderId: "Order ID",
  dateFrom: "Date: From",
  dateTo: "Date: To",
  noOrderFound: "No order found.",
  orderDetails: "Order Details",
  dateAdded: "Date Added",
  orderHistory: "Order History",
  airwaybill: "Air Waybill",
  trackingResult: "Tracking Result",
  errorTracking: "Tracking failed. Please try to track in the coresponding courier website.",
  shipmentStatus: "Shipment Status",
  shipmentDate: "Shipment Date",
  noShipmentHistory: "No shipment history.",
  confirmPackageReceived: "Confirm Package Received",
  orderFinishConfirm: "Are You sure that You have received the package accordingly and in good condition?",
  orderFinishThankYou: "Congratulations Your order had been completed. Thank you for shopping with us!",
  paymentDetails: "Payment Details",
  vaNumbers: "Virtual Account Number",
  mailInvoice: "Mail Invoice",
  successMailInvoice: "Invoice successfully sent to Your email.",
  /* RETURN */
  returnText: "Return",
  returnBtnTooltip: "Return this Product",
  /* VOUCHER */
  voucherText: "Voucher",
  voucherCode: "Voucher Code",
  copyCodeTextTooltip: "Copy Code",
  /* PRODUCT */
  productText: "Product",
  allProduct: "All Product",
  productAddFavourite: "Add to Favourite",
  productRemoveFavourite: "Remove from Favourite",
  productShare: "Share Product",
  noProductFound: "No product found.",
  addToCart: "Add to Cart",
  toFavourite: "Add to Favourite",
  isFavourite: "Favourite",
  productCode: "Product Code",
  stockText: "Stock",
  minOrder: "Minimum Order",
  descriptionText: "Description",
  specificationText: "Specification",
  reviewText: "Review",
  writeReview: "Write Review",
  ratingText: "Rating",
  noReview: "No review found.",
  errGiveRating: "Please give rating by clicking the stars buttons.",
  errFillReview: "Please write Your review.",
  successReviewSubmitted: "Your review had been submitted and awaiting admin approval.",
  volumeDiscount: "Volume Discount",
  loginBeforeAddToCart: "Please login before adding to cart.",
  errQtyEmpty: "Qty can't be empty",
  errQtyMin: "Qty can't be lower than the minimum required",
  errQtyMax: "Qty exceeding product stock",
  errQtyMax100: "Maximum product qty are 100",
  pleaseChoose: "Please choose",
  successAddToCart: "This product had been added to cart.",
  relatedProduct: "Related Product",
  /* FAVOURITE */
  favouriteText: "Favourite",
  /* FOOTER */
  footerPoint1: "Easy Payment",
  footerPoint1Desc: "A wide variety of options for payment. For payment with credit card, please contact us via chat.",
  footerPoint2: "Special Price",
  footerPoint2Desc: "To get special price, feel free to contact us via email.",
  footerPoint3: "Best Products",
  footerPoint3Desc: "Offering the best choice products with the latest innovations",
  /* 404 */
  pageNotFound: "Page Not Found",
  requestedPageNotFound: "The requested page not found. The page might no longer exist, or the url entered is invalid."
};