export const DEFAULT_LOCALE = "id";
export const STORE_NAME = "PrismaKlik";
export const MAIN_URL = "https://prismaklik.com";
export const ADMIN_PANEL_URL = "https://admin.prismaklik.com";
export const BACK_END_URL = "https://be.prismaklik.com";
export const IMAGE_BASE_URL = "https://catalog.prismaklik.com/image/"
export const PROFILE_PICTURE_PATH = "/images/customer/profile_pictures";
export const RECAPTCHA_CLIENT_KEY = "6Le99OMZAAAAAI4lOIxQyUYXcwNBmq2cvhZ8vdel";
export const MIDTRANS_CLIENT_KEY = "Mid-client-Qe_cYvoI2t7kG6wr";
export const MIDTRANS_SNAP_URL = "https://app.midtrans.com/snap/snap.js";
export const ADDTHIS_API_URL = "http://api.addthis.com/oexchange/0.8/offer";
export const ADDTHIS_PUBLIC_KEY = "ra-5fc7717c3becd954";
export const INVOICE_PREFIX = "PR-KL";