
import React from 'react';
import { isEmptyArray } from './utilities.js';

export function addSubtractPrice(optData, price, qty) {
  if (optData.subtract === 1 && qty > optData.quantity) qty = optData.quantity;
  let optPrice = parseFloat(optData.price) * qty;
  if (price > 0) {
    if (optData.price_prefix === "+") price += optPrice;
    if (optData.price_prefix === "-" && price > optPrice) price -= optPrice;
  }
  return price;
}

export function listQtyPrice(responseData){
  let i, j, k, l, listQty = [], listPrice = [], tempPrice, tempOption, tempQty, totalQty = 0;
  for (i = 0; i < responseData.cart.length; i++) {
    listQty[responseData.cart[i].cart_id] = responseData.cart[i].quantity;

    tempQty = parseInt(responseData.cart[i].quantity);
    tempPrice = responseData.cart[i].price;
    if (responseData.cart[i].special_price) tempPrice = responseData.cart[i].special_price;
    if(!isEmptyArray(responseData.product_discount)){
      for (j = 0; j < responseData.product_discount.length; j++){
        if (responseData.cart[i].product_id === responseData.product_discount[j].product_id && tempQty >= responseData.product_discount[j].quantity){
          tempPrice = responseData.product_discount[j].price;
          break;
        }
      }
    }
    tempPrice = parseFloat(tempPrice) * tempQty;

    if (responseData.cart[i].option === "[]")
      listPrice[responseData.cart[i].cart_id] = tempPrice;
    else {
      tempOption = JSON.parse(responseData.cart[i].option);
      tempOption = Object.entries(tempOption);
      for (j = 0; j < tempOption.length; j++) {
        if (!isEmptyArray(tempOption[j][1])) {
          for (l = 0; l < tempOption[j][1].length; l++) {
            for (k = 0; k < responseData.product_option_value.length; k++) {
              if (
                parseInt(responseData.product_option_value[k].product_option_id) === parseInt(tempOption[j][0]) &&
                parseInt(responseData.product_option_value[k].product_option_value_id) === parseInt(tempOption[j][1][l])
              ) {
                tempPrice = addSubtractPrice(responseData.product_option_value[k], tempPrice, tempQty);
                break;
              }
            }
          }
        }
        else {
          for (k = 0; k < responseData.product_option_value.length; k++) {
            if (
              parseInt(responseData.product_option_value[k].product_option_id) === parseInt(tempOption[j][0]) &&
              parseInt(responseData.product_option_value[k].product_option_value_id) === parseInt(tempOption[j][1])
            ) {
              tempPrice = addSubtractPrice(responseData.product_option_value[k], tempPrice, tempQty);
              break;
            }
          }
        }
      }
      listPrice[responseData.cart[i].cart_id] = tempPrice;
    }
    totalQty += tempQty;
  }

  return [listQty, listPrice, totalQty];
}

export function liCartOptions(optionStr, product_option, product_option_value, prefix){
  let cartOption = [], tempOption, tempOptName, tempOptValName, j, k, l;

  if (optionStr !== "[]") {
    if (typeof prefix === "undefined" || prefix === undefined || prefix === null) prefix = "";

    tempOption = JSON.parse(optionStr);
    tempOption = Object.entries(tempOption);
    for (j = 0; j < tempOption.length; j++) {
      tempOptName = "";
      for (k = 0; k < product_option.length; k++)
        if (parseInt(product_option[k].product_option_id) === parseInt(tempOption[j][0]))
          tempOptName = product_option[k].name;
      if (!isEmptyArray(tempOption[j][1])) {
        tempOptValName = "";
        for (l = 0; l < tempOption[j][1].length; l++) {
          for (k = 0; k < product_option_value.length; k++) {
            if (
              parseInt(product_option_value[k].product_option_id) === parseInt(tempOption[j][0]) &&
              parseInt(product_option_value[k].product_option_value_id) === parseInt(tempOption[j][1][l])
            ) {
              if (l > 0) tempOptValName += ", ";
              tempOptValName += product_option_value[k].name;
            }
          }
        }
      }
      else {
        for (k = 0; k < product_option_value.length; k++) {
          if (
            parseInt(product_option_value[k].product_option_id) === parseInt(tempOption[j][0]) &&
            parseInt(product_option_value[k].product_option_value_id) === parseInt(tempOption[j][1])
          ) {
            tempOptValName = product_option_value[k].name;
          }
        }
      }
      cartOption.push(
        <li key={prefix + "cartOption" + j}><small className="text-muted">&#8208;&nbsp;{tempOptName}:&nbsp;{tempOptValName}</small></li>
      );
    }
  }

  return cartOption;
}