import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslate } from 'react-redux-multilingual';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Media from 'react-bootstrap/Media';
import Navbar from 'react-bootstrap/Navbar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import LinkLang from './LinkLang.js';
import Text from './Text.js';
import { renderRemoveFromCartTooltip } from './Tooltips.js';

import { getCartDataWithSaga } from '../redux/actions/index.js';
import { liCartOptions } from '../scripts/cartHelper.js';
import { fetchHandler } from '../scripts/fetchHandler.js';
import { formatNumber, isEmptyArray, replaceHTMLEntities, getTelLink } from '../scripts/utilities.js';
import * as CONFIG from '../config.js';

import '../styles/headerNav.css';

class HeaderNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: "",
      apiCartUrl: CONFIG.BACK_END_URL + "/api/cart/" + this.props.translate("id"),
      languages: [],
      search_top: "",
      store_config: {
        logo: "",
        email: "",
        telephone: ""
      },
      categoriesTop: [],
      categoriesAll: [],
      submiting: false
    };

    this.div_info_top_header = React.createRef();
    this.div_category_top_header = React.createRef();
    this.logo_scroll_minimize = React.createRef();
    this.info_scroll_minimize = React.createRef();
    this.container_top_header_lg = React.createRef();

    this.removeFromCart = this.removeFromCart.bind(this);
    this.getComponentData = this.getComponentData.bind(this);
    this.handleWindowScroll = this.handleWindowScroll.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
  }

  handleWindowScroll() {
    const topScroll = window.scrollY;
    if (topScroll > 91) {
      this.div_info_top_header.current.classList.remove('col-4');
      this.div_info_top_header.current.classList.add('col-2');

      this.div_category_top_header.current.classList.remove('d-none');
      this.logo_scroll_minimize.current.classList.remove('d-none');
      this.info_scroll_minimize.current.classList.add('d-none');

      this.container_top_header_lg.current.style.backgroundColor = "#ffffff";
      this.container_top_header_lg.current.style.borderBottom = "solid 1px #dddddd";
    }
    else {
      this.div_info_top_header.current.classList.remove('col-2');
      this.div_info_top_header.current.classList.add('col-4');

      this.div_category_top_header.current.classList.add('d-none');
      this.logo_scroll_minimize.current.classList.add('d-none');
      this.info_scroll_minimize.current.classList.remove('d-none');

      this.container_top_header_lg.current.style.backgroundColor = "#f5f5f5";
      this.container_top_header_lg.current.style.borderBottom = "solid 1px #f5f5f5";
    }
  }

  handleChange(evt) {
    let newState = {};
    const elemId = evt.target.id.toString();
    newState[elemId] = evt.target.value;
    this.setState(newState);
  }

  handleSubmitSearch(evt) {
    evt.preventDefault();

    this.props.history.push('/' + this.props.translate("lang") + '/product/search?q=' + encodeURIComponent(this.state.search_top.trim()));

    evt.stopPropagation();
    return false;
  }

  removeFromCart(cart_id, evt) {
    if (!this.state.submiting) {
      const self = this;
      this.setState(
        { submiting: true },
        () => {
          fetchHandler(
            "GET",
            CONFIG.BACK_END_URL + "/api/cart/destroy/" + cart_id,
            true
          )
            .then(response => {
              if (response.status === 204)
                self.setState({ submiting: false }, () => { self.props.getCartData(self.state.apiCartUrl); });
              else
                return Promise.reject(new Error(response.status));
            })
            .catch(err => {
              console.log(err);
            });
        }
      );
    }
  }

  getComponentData(parProps, parState, isGetCart) {    
    isGetCart = isGetCart || parProps.userData.isLoggedIn !== this.props.userData.isLoggedIn || parProps.userData.status !== this.props.userData.status;
    let condGetCart = isGetCart && this.props.userData.isLoggedIn && this.props.userData.status === 1;
    
    const pathLang = this.props.location.pathname.split("/")[1];
    const self = this;
    if (this.state.lang !== this.props.translate("lang") && this.props.translate("lang") === pathLang) {
      this.setState(
        {lang: pathLang},
        () => {
          fetchHandler(
            "GET",
            CONFIG.BACK_END_URL + "/api/header/" + self.props.translate("id"),
            true
          )
            .then(response2 => {
              response2.json().then(function (responseData2) {
                let logo, email, telephone;
                for (let i = 0; i < responseData2.store_config.length; i++)
                  switch (responseData2.store_config[i].key) {
                    case 'config_email': email = responseData2.store_config[i].value; break;
                    case 'config_logo': logo = responseData2.store_config[i].value; break;
                    case 'config_telephone': telephone = responseData2.store_config[i].value; break;
                    default: break;
                  }
                let newState = {
                  languages: responseData2.languages,
                  store_config: {
                    logo: logo,
                    email: email,
                    telephone: telephone
                  },
                  categoriesTop: responseData2.top,
                  categoriesAll: responseData2.all
                };                
                self.setState(newState, () => { if (condGetCart) self.props.getCartData(self.state.apiCartUrl); });
              });
            });
        }
      );     
    }
    else if (condGetCart) this.props.getCartData(this.state.apiCartUrl);
  }

  componentDidUpdate(prevProps, prevState) {
    this.getComponentData(prevProps, prevState, false);
  }

  componentDidMount() {
    this.handleWindowScroll();
    window.addEventListener("scroll", this.handleWindowScroll);
    this.getComponentData({}, {}, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleWindowScroll);
  }

  render() {
    const lang = this.props.translate("lang");
    const textObj = {
      cartTextLong: this.props.translate("cartTextLong"),
      pluralSuffix: this.props.translate("pluralSuffix"),
      itemText: this.props.translate("itemText"),
      searchProductText: this.props.translate("searchProductText")
    };
    const defaultUserImage = (
      <Dropdown.Toggle as="a" role="button" className="outer_circle_icon circle_icon_default" >
        <div>
          <i className="fa fa-fw fa-user" aria-hidden="true" />
        </div>
      </Dropdown.Toggle>
    );

    let userName = "", userMenu = null, langMenu = null, langMenuMobile = null, i;
    if (this.props.userData.isLoggedIn) {
      let userImage = defaultUserImage;
      if (this.props.userData.name) {
        userName = (<LinkLang to="/account"><strong>Hi, {this.props.userData.name}</strong></LinkLang>);
        if (this.props.userData.image !== "") {
          let imageUrl = CONFIG.BACK_END_URL + CONFIG.PROFILE_PICTURE_PATH + "/" + this.props.userData.image;
          userImage = (
            <Dropdown.Toggle as="a" role="button" className="d-block">
              <Image
                src={imageUrl}
                alt={this.props.userData.name}
                className="avatar avatar_xs"
                roundedCircle
              />
            </Dropdown.Toggle>
          );
        }
      }
      let userMenuItem = [];
      userMenuItem.push(
        <a key="userMenuItemDesktopLogout" role="button" className="dropdown-item" onClick={this.props.logout}>
          {this.props.translate("logoutText")}
        </a>
      );
      if (this.props.userData.status === 1) {
        /* <LinkLang key="userMenuItemDesktopReturn" className="dropdown-item" to="/account/return">
            {this.props.translate("returnText")}
          </LinkLang>,
          <LinkLang key="userMenuItemDesktopVoucher" className="dropdown-item" to="/account/voucher">
            {this.props.translate("voucherText")}
          </LinkLang>, */
        userMenuItem.unshift(
          <LinkLang key="userMenuItemMyAccount" className="dropdown-item" to="/account">
            {this.props.translate("myAccountText")}
          </LinkLang>,
          <LinkLang key="userMenuItemDesktopOrder" className="dropdown-item" to="/account/order">
            <Text tid="orderText" isPlural={true} />
            {
              this.props.orderCount > 0 ?
                <>
                  &nbsp;&nbsp;
                  <span className="badge badge-pill badge-danger">{this.props.orderCount}</span>
                </>
                :
                null
            }
          </LinkLang>,
          <LinkLang key="userMenuItemDesktopFavourite" className="dropdown-item" to="/account/favourite">
            {this.props.translate("menuFavourite")}
          </LinkLang>,
          <LinkLang key="userMenuItemDesktopSetting" className="dropdown-item" to="/account/setting">
            {this.props.translate("settingText")}
          </LinkLang>
        );
      }
      userMenu = (
        <>
          {userImage}
          <Dropdown.Menu alignRight>
            {userMenuItem}
            <div className="triangle triangle_color_primary triangle_top_header_right"></div>
          </Dropdown.Menu>
        </>
      );
    }
    else {
      userName = "";
      userMenu = (
        <>
          {defaultUserImage}
          <Dropdown.Menu alignRight>
            <LinkLang className="dropdown-item" to="/login">{this.props.translate("loginText")}</LinkLang>
            <LinkLang className="dropdown-item" to="/account/register">{this.props.translate("registerText")}</LinkLang>
            <div className="triangle triangle_color_primary triangle_top_header_right"></div>
          </Dropdown.Menu>
        </>
      );

      let langMenuItem = [], activeFlagImage = "";
      if (!isEmptyArray(this.state.languages)) {
        let newUrlLang = "", currentPathArr, tempLocale;
        const excludedPathArr = ["product", "page"];
        currentPathArr = this.props.location.pathname.split("/");
        currentPathArr.shift();
        const pathLang = currentPathArr[0];
        currentPathArr.shift();
        for (i = 0; i < this.state.languages.length; i++) {
          let activeClass = "", flagImage = "";
          flagImage = CONFIG.ADMIN_PANEL_URL + "/language/" + this.state.languages[i].code + "/" + this.state.languages[i].image;
          if (this.state.languages[i].code === this.props.translate("code")) {
            activeClass = " active";
            activeFlagImage = flagImage;
          }

          tempLocale = this.state.languages[i].code.split("-")[0].toLowerCase();
          newUrlLang = "/" + tempLocale;
          if (
            typeof currentPathArr[0] !== "undefined" &&
            currentPathArr[0] !== undefined &&
            currentPathArr[0] !== null &&
            currentPathArr[0] !== "" &&
            (
              (
                pathLang === tempLocale &&
                excludedPathArr.includes(currentPathArr[0])
              )
              ||
              !excludedPathArr.includes(currentPathArr[0])
            )
          ) newUrlLang += "/" + currentPathArr.join("/") + this.props.location.search;

          langMenuItem.push(
            <Link className={"dropdown-item" + activeClass} to={newUrlLang}>
              <img src={flagImage} alt={tempLocale.toUpperCase()} />
              &nbsp;
              {this.state.languages[i].name}
            </Link>
          );
        }
      }
      langMenu = (
        <Dropdown className="container_top_right_item hide_dropdown_caret">
          <Dropdown.Toggle as="a" role="button" className="outer_circle_icon circle_icon_default">
            <div>
              <img src={activeFlagImage} alt={lang.toUpperCase()} />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight>
            {langMenuItem}
            <div className="triangle triangle_color_primary triangle_top_header_right"></div>
          </Dropdown.Menu>
        </Dropdown>
      );
      langMenuMobile = (
        <Dropdown className="hide_dropdown_caret">
          <Dropdown.Toggle as="a" role="button">
            <img src={activeFlagImage} alt={lang.toUpperCase()} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {langMenuItem}
            <div className="triangle triangle_color_primary triangle_top_header_left"></div>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    let cartList = [], cartLength = this.props.cartData.cart.length, tempProdLink, subTotal = 0;
    for (i = 0; i < cartLength; i++) {
      subTotal += this.props.cartData.listPrice[this.props.cartData.cart[i].cart_id];

      if (this.props.location.pathname !== "/" + lang + "/cart" && this.props.location.pathname !== "/" + lang + "/checkout") {
        tempProdLink = "/product";
        if (this.props.cartData.cart[i].seo_url) tempProdLink += "/" + this.props.cartData.cart[i].seo_url;
        else tempProdLink += "?product_id=" + this.props.cartData.cart[i].product_id;

        if (i > 0) cartList.push(<hr key={"hrCart" + i} />);
        cartList.push(
          <tr key={"headerCartList" + i}>
            <td>
              <Media className="position-relative">
                <img className="img_thumbnail_product_sm mr-2" src={CONFIG.IMAGE_BASE_URL + this.props.cartData.cart[i].image} alt={this.props.cartData.cart[i].name} />
                <Media.Body>
                  <LinkLang to={tempProdLink} className="stretched-link">{replaceHTMLEntities(this.props.cartData.cart[i].name)}</LinkLang>
                  <br />
                  <ul className="ul_details_top_shopping_cart">
                    <li><small className="text-muted">&#8208;&nbsp;{this.props.translate("qtyText")}:&nbsp;{this.props.cartData.listQty[this.props.cartData.cart[i].cart_id]}</small></li>
                    {liCartOptions(this.props.cartData.cart[i].option, this.props.cartData.product_option, this.props.cartData.product_option_value, "header")}
                  </ul>
                </Media.Body>
              </Media>
            </td>
            <td className="text-right">
              <span className="text-nowrap">Rp&nbsp;{formatNumber(this.props.cartData.listPrice[this.props.cartData.cart[i].cart_id], 0)}</span>
            </td>
            <td className="text-right">
              <OverlayTrigger placement="top" overlay={renderRemoveFromCartTooltip}>
                <button type="button" className="btn_remove_1" onClick={this.removeFromCart.bind(this, this.props.cartData.cart[i].cart_id)} disabled={this.state.submiting}>
                  <i className="fa fa-trash-o" aria-hidden="true" />
                </button>
              </OverlayTrigger>
            </td>
          </tr>
        );
      }
    }
    let cartIconTitle = "", pluralSuffix = "";
    if (this.props.cartData.itemCount > 1) pluralSuffix = textObj.pluralSuffix;
    if (this.props.cartData.itemCount > 0) cartIconTitle = this.props.cartData.itemCount + " " + textObj.itemText + pluralSuffix + " - Rp " + formatNumber(subTotal, 0);

    let topCategories = [], catLink = "";
    if (!isEmptyArray(this.state.categoriesTop)) {
      for (i = 0; i < this.state.categoriesTop.length; i++) {
        catLink = "/product/category/?category_id=" + this.state.categoriesTop[i].category_id;
        if (typeof this.state.categoriesTop[i].seo_url !== "undefined" && this.state.categoriesTop[i].seo_url !== undefined && this.state.categoriesTop[i].seo_url !== null && this.state.categoriesTop[i].seo_url !== "")
          catLink = "/product/category/" + this.state.categoriesTop[i].seo_url;
        topCategories.push(<li key={"topCat" + i} className="nav-item"><LinkLang className="nav-link" to={catLink}>{replaceHTMLEntities(this.state.categoriesTop[i].name)}</LinkLang></li>);
      }
    }

    let allCategories = [];
    if (!isEmptyArray(this.state.categoriesAll)) {
      for (i = 0; i < this.state.categoriesAll.length; i++) {
        catLink = "/product/category/?category_id=" + this.state.categoriesAll[i].category_id;
        if (typeof this.state.categoriesAll[i].seo_url !== "undefined" && this.state.categoriesAll[i].seo_url !== undefined && this.state.categoriesAll[i].seo_url !== null && this.state.categoriesAll[i].seo_url !== "")
          catLink = "/product/category/" + this.state.categoriesAll[i].seo_url;
        allCategories.push(<LinkLang key={"allCat" + i} className="dropdown-item" to={catLink}>{replaceHTMLEntities(this.state.categoriesAll[i].name)}</LinkLang>);
      }
    }
    return (
      <>
        {/* Desktop Version */}
        <div ref={this.container_top_header_lg} className="container_top_header_lg d-none d-xl-block">
          <Container>
            <Row className="padding_top_10 padding_bottom_10">
              <div className="col-4" ref={this.div_info_top_header}>
                <div className="div_valign_middle div_top_header_teks">
                  <div>
                    <Link to="" ref={this.logo_scroll_minimize} className="a_non_text container_main_logo_mini d-none">
                      {
                        this.state.store_config.logo !== "" ?
                          <img src={CONFIG.IMAGE_BASE_URL + this.state.store_config.logo} alt={CONFIG.STORE_NAME} />
                          :
                          null
                      }
                    </Link>
                    <div ref={this.info_scroll_minimize}>
                      <i className="fa fa-fw fa-phone text-primary" aria-hidden="true" />
                      <a href={"tel:" + getTelLink(this.state.store_config.telephone)}>{this.state.store_config.telephone}</a>
                      &nbsp;&nbsp;
                      <i className="fa fa-fw fa-envelope text-primary" aria-hidden="true" />
                      <a href={"mailto:" + this.state.store_config.email}>{this.state.store_config.email}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2" ref={this.div_category_top_header}>
                {
                  !isEmptyArray(allCategories) ?
                    <div className="group">
                      <div className="float-right">
                        <Dropdown className="navbar-text">
                          <Dropdown.Toggle as="a" className="d-block" role="button">
                            <strong>{this.props.translate("menuCategory")}</strong>
                          </Dropdown.Toggle>
                          <Dropdown.Menu alignRight>
                            {allCategories}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    :
                    null
                }
              </div>
              <div className="col-5">
                <Form onSubmit={this.handleSubmitSearch}>
                  <InputGroup>
                    <FormControl
                      id="search_top"
                      placeholder={textObj.searchProductText}
                      aria-label={textObj.searchProductText}
                      aria-describedby="button-header-search"
                      value={this.state.search_top}
                      onChange={this.handleChange}
                      required
                    />
                    <InputGroup.Append>
                      <Button type="submit" variant="primary" id="button-header-search">
                        <i className="fa fa-fw fa-search" aria-hidden="true" />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </div>
              <Col xs={3}>
                <Dropdown className="container_top_right_item hide_dropdown_caret">
                  <Dropdown.Toggle as="a" className="outer_circle_icon circle_icon_default" role="button" id="button_shopping_cart" aria-haspopup="true" title={cartIconTitle}>
                    <div>
                      <i className="fa fa-fw fa-shopping-cart" aria-hidden="true" />
                    </div>
                  </Dropdown.Toggle>
                  {
                    this.props.cartData.itemCount > 0 ?
                      <div className="badge_shopping_cart_counter">{this.props.cartData.itemCount}</div>
                      :
                      null
                  }
                  {
                    !isEmptyArray(cartList) ?
                      <Dropdown.Menu alignRight className="top_shopping_cart">
                        <ListGroup className="list-group-style1">
                          <ListGroup.Item className="group">
                            <div className="float-left">
                              <strong className="text-primary">{textObj.cartTextLong}</strong>
                            </div>
                            <div className="float-right">
                              {this.props.cartData.itemCount + " " + textObj.itemText + textObj.pluralSuffix}
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <Table className="table_no_left_right_padding">
                              <tbody>
                                {cartList}
                              </tbody>
                            </Table>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <div className="text-right">
                              <strong>{this.props.translate("subTotalText")}:&nbsp;<span className="text-primary text-nowrap">Rp&nbsp;{formatNumber(subTotal, 0)}</span></strong>
                              <br />
                              <small className="text-muted">
                                {this.props.translate("shippingNotYetIncludedText")}
                              </small>
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item className="group">
                            <div className="float-left">
                              <LinkLang role="button" className="btn btn-secondary" to="/cart">
                                <i className="fa fa-fw fa-shopping-cart" aria-hidden="true" />
                                &nbsp;
                                {this.props.translate("cartTextLong")}
                              </LinkLang>
                            </div>
                            <div className="float-right">
                              <LinkLang role="button" className="btn btn-primary" to="/checkout">
                                <i className="fa fa-fw fa-credit-card-alt" aria-hidden="true" />
                                &nbsp;
                                {this.props.translate("proceedCheckOutText")}
                              </LinkLang>
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                        <div className="triangle triangle_color_primary triangle_top_header_right"></div>
                      </Dropdown.Menu>
                      :
                      null
                  }
                </Dropdown>

                {langMenu}

                <Dropdown className="container_top_right_item hide_dropdown_caret">
                  {userMenu}
                </Dropdown>

                <div className="container_top_right_item">
                  <div className="div_valign_middle div_top_header_teks">
                    <div>
                      {userName}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="container_nav_header_lg d-none d-xl-block">
          <Container className="my-4">
            <Row>
              <Col>
                <Navbar bg="light" expand="lg">
                  <Navbar.Brand as="div">
                    {
                      this.state.store_config.logo !== "" ?
                        <LinkLang to="">
                          <img src={CONFIG.IMAGE_BASE_URL + this.state.store_config.logo} alt={CONFIG.STORE_NAME} height="48" className="d-inline-block align-top" />
                        </LinkLang>
                        :
                        null
                    }
                  </Navbar.Brand>
                  <Navbar.Collapse>
                    {
                      !isEmptyArray(topCategories) ?
                        <ul className="navbar-nav mx-auto">{topCategories}</ul>
                        :
                        null
                    }
                    {
                      !isEmptyArray(allCategories) ?
                        <Dropdown className="navbar-text">
                          <Dropdown.Toggle as="a" className="d-block" role="button">
                            <strong>{this.props.translate("menuCategory")}</strong>
                          </Dropdown.Toggle>
                          <Dropdown.Menu alignRight>
                            {allCategories}
                          </Dropdown.Menu>
                        </Dropdown>
                        :
                        null
                    }
                  </Navbar.Collapse>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Mobile Version */}
        <div className="container_top_header_sm d-xl-none">
          <Container fluid className="p-0">
            <Row className="padding_top_10 padding_bottom_10">
              <Col xs={4} sm={3}>
                <div className="container_btn_header_mobile float-left">
                  <div role="button" className="div_valign_middle btn_header_mobile" onClick={this.props.openLeftNav}>
                    <div className="text-center">
                      <i className="fa fa-fw fa-bars text-primary" aria-hidden="true" />
                    </div>
                  </div>
                </div>
                <div className="container_btn_header_mobile float-left">
                  <div className="div_valign_middle btn_header_mobile">
                    <div className="text-center">
                      {langMenuMobile}
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={4} sm={6}>
                {
                  this.state.store_config.logo !== "" ?
                    <div className="div_valign_middle div_top_header_teks mx-auto">
                      <div>
                        <LinkLang to="" className="a_non_text container_main_logo_mini d-block">
                          <img src={CONFIG.IMAGE_BASE_URL + this.state.store_config.logo} alt={CONFIG.STORE_NAME} />
                        </LinkLang>
                      </div>
                    </div>
                    :
                    null
                }
              </Col>
              <Col xs={4} sm={3}>
                <div className="float-right position-relative container_btn_header_mobile">
                  <LinkLang role="button" className="div_valign_middle btn_header_mobile" to="/cart">
                    <div className="text-center">
                      <i className="fa fa-fw fa-shopping-cart text-primary" aria-hidden="true" />
                    </div>
                  </LinkLang>
                  {
                    this.props.cartData.itemCount > 0 ?
                      <div className="badge_shopping_cart_counter">{this.props.cartData.itemCount}</div>
                      :
                      null
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="header-spacer d-xl-none"></div>
      </>
    );
  }
}

const HeaderNavBundled = withTranslate(withRouter(HeaderNav))
const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, state.rootReducer);
};
const mapDispatchToProps = dispatch => {
  return {
    getCartData: url => dispatch(getCartDataWithSaga(url))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavBundled);