import React, { lazy } from 'react'
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

const Cart = lazy(() => import('./Pages/Cart.js'));
const CheckOutIndex = lazy(() => import('./Pages/CheckOut/Index.js'));
const CheckOutFailed = lazy(() => import('./Pages/CheckOut/Failed.js'));
const CheckOutPending = lazy(() => import('./Pages/CheckOut/Pending.js'));
const CheckOutSuccess = lazy(() => import('./Pages/CheckOut/Success.js'));
const ContactUs = lazy(() => import('./Pages/ContactUs.js'));
const Help = lazy(() => import('./Pages/Help.js'));
const Information = lazy(() => import('./Pages/Information.js'));
const Home = lazy(() => import('./Pages/Home.js'));
const ProductCategory = lazy(() => import('./Pages/Product/Category.js'));
const ProductDetails = lazy(() => import('./Pages/Product/Details.js'));
const ProductSearch = lazy(() => import('./Pages/Product/Search.js'));
const AccountIndex = lazy(() => import('./Pages/Account/Index.js'));
const AccountVerify = lazy(() => import('./Pages/Account/Verify.js'));
const Favourite = lazy(() => import('./Pages/Account/Favourite.js'));
const Setting = lazy(() => import('./Pages/Account/Setting.js'));
//const ChangeEmail = lazy(() => import('./Pages/Account/ChangeEmail.js'));
const ChangePassword = lazy(() => import('./Pages/Account/ChangePassword.js'));
const FormAddress = lazy(() => import('./Pages/Account/FormAddress.js'));
//const Voucher = lazy(() => import('./Pages/Account/Voucher.js'));
const Order = lazy(() => import('./Pages/Account/Order.js'));
const OrderDetails = lazy(() => import('./Pages/Account/OrderDetails.js'));
//const Return = lazy(() => import('./Pages/Account/Return.js'));
//const ReturnForm = lazy(() => import('./Pages/Account/ReturnForm.js'));
//const ReturnDetails = lazy(() => import('./Pages/Account/ReturnDetails.js'));
const Register = lazy(() => import('./Pages/Account/Register.js'));
const Login = lazy(() => import('./Pages/Login.js'));
const ForgotPassword = lazy(() => import('./Pages/ForgotPassword.js'));
const ResetPassword = lazy(() => import('./Pages/ResetPassword.js'));
const NotFound404 = lazy(() => import('./Pages/404.js'));

function checkAuthVer(propsData, needAuth, needVer) {
  let continueFlag = 1;

  if (needAuth) {
    if (propsData.userData.isLoggedIn) {
      if (needVer && parseInt(propsData.userData.status) !== 1) continueFlag = 0;
    }
    else continueFlag = -1;
  }

  switch (continueFlag) {
    case (-1):
      return <Redirect to={"/" + propsData.locale + "/login"} />;
    case 0:
      return <Redirect to={"/" + propsData.locale + "/account"} />;
    case 1:
    default:
      return null;
  }
}

export function Routes(props) {
  const propsData = Object.assign({}, props);
  const langPath = "/" + propsData.locale;
  const checkResultAuth = checkAuthVer({ ...propsData }, true, false);
  const checkResultAuthVer = checkAuthVer({ ...propsData }, true, true);
  return (
    <Switch>
      <Route exact
        path={langPath + "/account/order/:id([0-9]+)"}
        component={(routeProps) => {
          if (checkResultAuthVer === null) return <OrderDetails {...routeProps} />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/account/order"}
        component={(routeProps) => {
          if (checkResultAuthVer === null) return <Order {...routeProps} />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/product/category/:slug([a-z0-9-]+)"}
        component={(routeProps) => <ProductCategory openSnackBar={propsData.openSnackBar} {...routeProps} />}
      />
      <Route exact
        path={langPath + "/product/category"}
        component={(routeProps) => <ProductCategory openSnackBar={propsData.openSnackBar} {...routeProps} />}
      />
      <Route exact
        path={langPath + "/product/search"}
        component={(routeProps) => <ProductSearch openSnackBar={propsData.openSnackBar} {...routeProps} />}
      />
      <Route exact
        path={langPath + "/product/:slug([a-z0-9-]+)"}
        component={(routeProps) => <ProductDetails openSnackBar={propsData.openSnackBar} {...routeProps} />}
      />
      <Route exact
        path={langPath + "/product"}
        component={(routeProps) => <ProductDetails openSnackBar={propsData.openSnackBar} {...routeProps} />}
      />
      <Route exact
        path={langPath + "/page/:slug([a-z0-9-]+)"}
        component={(routeProps) => <Information {...routeProps} />}
      />
      <Route exact
        path={langPath + "/page"}
        component={(routeProps) => <Information {...routeProps} />}
      />
      <Route exact
        path={langPath + "/help"}
        component={() => <Help />}
      />
      <Route exact
        path={langPath + "/contact-us"}
        component={() => <ContactUs />}
      />
      <Route exact
        path={langPath + "/checkout/failed"}
        component={(routeProps) => {
          if (checkResultAuthVer === null) return <CheckOutFailed {...routeProps} />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/checkout/pending"}
        component={(routeProps) => {
          if (checkResultAuthVer === null) return <CheckOutPending {...routeProps} />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/checkout/success"}
        component={(routeProps) => {
          if (checkResultAuthVer === null) return <CheckOutSuccess {...routeProps} />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/checkout"}
        component={() => {
          if (checkResultAuthVer === null) return <CheckOutIndex />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/cart"}
        component={() => {
          if (checkResultAuthVer === null) return <Cart />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/account/verify/:id([0-9]+)/:hash([a-z0-9]+)"}
        component={(routeProps) => <AccountVerify {...routeProps} />}
      />
      <Route exact
        path={langPath + "/account/setting/address/edit/:id([0-9]+)"}
        component={(routeProps) => {
          if (checkResultAuthVer === null) return <FormAddress {...routeProps} />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/account/setting/address/add"}
        component={(routeProps) => {
          if (checkResultAuthVer === null) return <FormAddress {...routeProps} />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/account/setting/change-password"}
        component={() => {
          if (checkResultAuthVer === null) return <ChangePassword />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/account/setting"}
        component={() => {
          if (checkResultAuthVer === null) return <Setting />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/account/favourite"}
        component={(routeProps) => {
          if (checkResultAuthVer === null) return <Favourite openSnackBar={propsData.openSnackBar} {...routeProps} />;
          else return checkResultAuthVer;
        }}
      />
      <Route exact
        path={langPath + "/account/register"}
        component={() => <Register />}
      />
      <Route exact
        path={langPath + "/account"}
        component={(routeProps) => {
          if (checkResultAuth === null) return <AccountIndex {...routeProps} />;
          else return checkResultAuth;
        }}
      />
      <Route exact
        path={langPath + "/reset-password/:hash([a-z0-9]+)"}
        component={(routeProps) => <ResetPassword checkAuth={propsData.checkAuth} {...routeProps} />}
      />
      <Route exact
        path={langPath + "/forgot-password"}
        component={() => <ForgotPassword />}
      />
      <Route exact
        path={langPath + "/login"}
        component={(routeProps) => <Login {...routeProps} />}
      />
      <Route exact
        path={langPath + "/404"}
        component={() => <NotFound404 />}
      />
      <Route exact
        path={langPath}
        component={() => <Home openSnackBar={propsData.openSnackBar} />}
      />
      <Route exact
        path="/"
        component={() => <Redirect to={langPath} />}
      />
      <Route component={NotFound404} />
    </Switch>
  );
}

function mapStateToProps(state, ownProps) {
  return Object.assign({}, ownProps, state.Intl, { userData: state.rootReducer.userData });
};

export default connect(
  mapStateToProps
)(Routes);