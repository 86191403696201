import React from 'react';
import '../styles/loaderAnimation.css';

function LoaderAnimation (){
  return (
    <div className="div_valign_middle w-100 h-100">
      <div className="text-center">
        <div className="cssload-piano mx-auto">
          <div className="cssload-rect1"></div>
          <div className="cssload-rect2"></div>
          <div className="cssload-rect3"></div>
        </div>
      </div>
    </div>
  );
}

export default LoaderAnimation;