import * as CONFIG from '../config.js';

export function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
export function backToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}
export function isWindowXS() {
  return window.innerWidth < 576;
}
export function isWindowSM() {
  var winWidth = window.innerWidth;
  return (winWidth > 575 && winWidth < 768);
}
export function isWindowMD() {
  var winWidth = window.innerWidth;
  return (winWidth > 767 && winWidth < 992);
}
export function isWindowLG() {
  var winWidth = window.innerWidth;
  return (winWidth > 991 && winWidth < 1200);
}
export function isWindowXL() {
  return window.innerWidth > 1199;
}

export function isObject(value) {
  return value && value !== undefined && typeof value === 'object' && value.constructor === Object;
}
export function isArray(value) {
  return value && value !== undefined && typeof value === 'object' && value.constructor === Array;
}
export function isEmptyObject(object) {
  let isEmpty = true;
  if (isObject(object)) {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        isEmpty = false;
        break
      }
    }
  }
  return isEmpty;
}
export function isEmptyArray(arr) {
  let isEmpty = true;
  if (isArray(arr) && arr.length > 0) isEmpty = false;
  return isEmpty;
}
export function formatNumber(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  amount = parseFloat(amount);
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}
export function countPercent(amount1, amount2, decimalCount = 2, rest = false) {
  let percent = parseFloat(amount1) / parseFloat(amount2);
  percent = percent * 100;
  if (rest) percent = 100 - percent;
  return percent.toFixed(decimalCount);
}
export function padString(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
export function replaceHTMLEntities(str){
  str = str.toString();
  str = str.replace("&amp;", "&");
  str = str.replace("&lt;", "<");
  str = str.replace("&gt;", ">");
  str = str.replace('&quot;', '"');
  str = str.replace(/(\r\n|\n|\r)/gm, "");
  return str;
}
export function copyToClipboard(containerid, successFunc) {
  let range;
  if (document.selection) {
    document.selection.empty(); // IE

    range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select().createTextRange();
    document.execCommand("Copy");

    if (typeof successFunc !== "undefined" && successFunc !== undefined && successFunc !== null) successFunc();
  }
  else if (window.getSelection) {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges();
    }

    range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().addRange(range);
    document.execCommand("Copy");

    if (typeof successFunc !== "undefined" && successFunc !== undefined && successFunc !== null) successFunc();
  }
}
export function generateProductLinkPrice(prodObj){
  let discAmount, discPercent, prodLink;
  prodLink = "/product?product_id=" + prodObj.product_id;
  if (typeof prodObj.seo_url !== "undefined" && prodObj.seo_url !== undefined && prodObj.seo_url !== null && prodObj.seo_url !== "")
    prodLink = "/product/" + prodObj.seo_url;
  discAmount = 0;
  discPercent = 0;
  if (typeof prodObj.special_price !== "undefined" && prodObj.special_price !== undefined) {
    discAmount = prodObj.special_price;
    discPercent = countPercent(discAmount, prodObj.price, 0, true);
  }

  return [prodLink, discAmount, discPercent];
}
export function share(title, url, text){
  if (window.navigator.share) {
    window.navigator.share({
      title: title,
      url: url,
      text: text
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }
  else
    window.open(CONFIG.ADDTHIS_API_URL + '?url=' + encodeURIComponent(url) + '&title=' + encodeURIComponent(title) + '&description=' + encodeURIComponent(text) + '&pubid=' + CONFIG.ADDTHIS_PUBLIC_KEY, 'sharer', 'width=640,height=480,scrollbars=1,menubar=0,toolbar=0,status=0,location=0');
}

export function getTelLink(telLink){
  telLink = telLink.replace(" ", "");
  telLink = telLink.replace("(", "");
  telLink = telLink.replace(")", "");
  telLink = telLink.substring(1);
  telLink = "+62" + telLink;
  return telLink;
}