import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import Text from './Text.js';


export const renderBackToTopTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="backToTopText" />
  </Tooltip>
);
export const renderEmailTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="emailText" />
  </Tooltip>
);
export const renderProfilePictureTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="profilePicture" />
  </Tooltip>
);
export const renderFullNameTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="fullNameText" />
  </Tooltip>
);
export const renderBirthdayTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="birthdayText" />
  </Tooltip>
);
export const renderPhoneNumberTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="phoneNumberText" />
  </Tooltip>
);
export const renderLanguageTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="languageText" />
  </Tooltip>
);
export const renderSexTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="sexText" />
  </Tooltip>
);
export const renderPasswordTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="passwordText" />
  </Tooltip>
);
export const renderConfirmPasswordTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="confirmPasswordText" />
  </Tooltip>
);
export const renderNewPasswordTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="newPasswordText" />
  </Tooltip>
);
export const renderConfirmNewPasswordTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="confirmNewPasswordText" />
  </Tooltip>
);
export const renderCloseTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="closeText" />
  </Tooltip>
);
export const renderFavouriteTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="productAddFavourite" />
  </Tooltip>
);
export const renderRemoveFavouriteTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="productRemoveFavourite" />
  </Tooltip>
);
export const renderShareTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="productShare" />
  </Tooltip>
);
export const renderRefreshCartQtyTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="refreshCartQty" />
  </Tooltip>
);
export const renderRemoveFromCartTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="cartRemove" />
  </Tooltip>
);
export const renderCopyCodeToClipboardTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="copyCodeTextTooltip" />
  </Tooltip>
);
export const renderReturnProductButttonTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="returnBtnTooltip" />
  </Tooltip>
);
export const renderShowPasswordButttonTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="formShowPasswordButton" />
  </Tooltip>
);
export const renderHidePasswordButttonTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="formHidePasswordButton" />
  </Tooltip>
);
export const renderRemoveProfilePictureButtonTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="removeProfilePicture" />
  </Tooltip>
);
export const renderOrganizationTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="organizationText" />
  </Tooltip>
);
export const renderAddressTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="addressText" />
  </Tooltip>
);
export const renderPostCodeTooltip = (props) => (
  <Tooltip {...props}>
    <Text tid="postZipCode" />
  </Tooltip>
);