import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslate } from 'react-redux-multilingual';

/* import $ from "jquery"; */

import {
  Button,
  Card,
  Form,
  FormControl,
  Image,
  InputGroup,
  ListGroup,
  OverlayTrigger
} from 'react-bootstrap';

import LinkLang from './LinkLang.js';
import Text from './Text.js';
import { renderCloseTooltip } from './Tooltips.js';
import * as CONFIG from '../config.js';

/* function loadLeftNavBack(param) {
  if (param === 0) {
    $(".menu_list").css("display", "none");
    //$("#left_nav_menu_list_main").show('slide', { direction: 'left' }, 350);
    $("#left_nav_menu_list_main").show(350);
  }
  else if (param === 1 || param === 2) {
    $(".menu_list").css("display", "none");
    //$("#left_nav_menu_list_cat_lv" + param).show('slide', { direction: 'left' }, 350);
    $("#left_nav_menu_list_cat_lv" + param).show(350);
  }
} */

function LeftNav(props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [searchLeft, setSearchLeft] = useState("");
  const t = useTranslate();

  /* const loadLeftNavCat = (paramLevel, paramId,) => {
    let loaderWidth = 420;
    if (window.innerWidth < 420) loaderWidth = window.innerWidth;
    props.showLoaderOverlay(loaderWidth, "px");

    setTimeout(function () {
      var htmlResult = "loaded list kategori";
      if (paramLevel === 1 && paramId === 0) {
        if (htmlResult !== '') {
          props.hideLoaderOverlay();
          $(".menu_list").css("display", "none");
          //$("#left_nav_menu_list_cat_lv1").show('slide', { direction: 'right' }, 350);
          $("#left_nav_menu_list_cat_lv1").show(350);
        }
      }
      else if (paramLevel === 2 && paramId > 0) {
        if (htmlResult !== '') {
          props.hideLoaderOverlay();
          $(".menu_list").css("display", "none");
          //$("#left_nav_menu_list_cat_lv2").show('slide', { direction: 'right' }, 350);
          $("#left_nav_menu_list_cat_lv2").show(350);
        }
      }
      else if (paramLevel === 3) {
        if (htmlResult !== '') {
          props.hideLoaderOverlay();
          $(".menu_list").css("display", "none");
          //$("#left_nav_menu_list_cat_lv3").show('slide', { direction: 'right' }, 350);
          $("#left_nav_menu_list_cat_lv3").show(350);
        }
      }
      else props.hideLoaderOverlay();
    }, 2000);//nanti apus delay nya
  }; */

  const handleSubmitSearch = (evtSubmit) => {
    evtSubmit.preventDefault();

    history.push('/' + t("lang") + '/product/search?q=' + encodeURIComponent(searchLeft.trim()));
    props.closeLeftNav();

    evtSubmit.stopPropagation();
    return false;
  };

  const handleChangeSearchLeft = (evtChange) => {
    setSearchLeft(evtChange.target.value);
  };

  const checkActiveMenu = (menuidx) => {
    let isActive = false;
    let currentPathArr = pathname.split("/");
    switch (menuidx) {
      case 0:
        isActive = (currentPathArr[2] === "product");
        break;
      case 2:
        isActive = (currentPathArr[2] === "account" && currentPathArr[3] === "order");
        break;
      case 3:
        isActive = (currentPathArr[2] === "account" && currentPathArr[3] === "favourite");
        break;
      /* case 4:
        break;
      case 5:
        break; */
      case 6:
        isActive = (currentPathArr[2] === "account" && currentPathArr[3] === "setting");
        break;
      case 7:
        isActive = (currentPathArr[2] === "help");
        break;
      case 8:
        isActive = (currentPathArr[2] === "contact-us");
        break;
      default:
        break;
    }
    if (isActive) return " active";
    else return "";
  };

  useEffect(() => {
    props.closeLeftNav();
    window.addEventListener("resize", props.closeLeftNav);

    /* $("#left_nav_menu_list_cat_lv1").css("display", "none");
    $("#left_nav_menu_list_cat_lv2").css("display", "none");
    $("#left_nav_menu_list_cat_lv3").css("display", "none"); */
    return () => { window.removeEventListener("resize", props.closeLeftNav); };
  }, []);

  let closeBtnClass = " d-none";
  if (props.isOpen) closeBtnClass = "";

  let userImageSrc = CONFIG.IMAGE_BASE_URL + "catalog/profile-pic.jpg";
  if (props.userData.isLoggedIn && props.userData.image !== "")
    userImageSrc = CONFIG.BACK_END_URL + CONFIG.PROFILE_PICTURE_PATH + "/" + props.userData.image;

  return (
    <>
      <Card.Header>
        <div className="text-center font-weight-bold">
          {
            props.userData.isLoggedIn ?
              <LinkLang to="/account" onClick={props.closeLeftNav}>
                <Image
                  src={userImageSrc}
                  alt="Profile Picture"
                  className="avatar avatar_lg mb-2"
                  roundedCircle
                />
                <br />
                Hi, {props.userData.name}
              </LinkLang>
              :
              <>
                <LinkLang to="/login" onClick={props.closeLeftNav}>{t("loginText")}</LinkLang>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <LinkLang to="/account/register" onClick={props.closeLeftNav}>{t("registerText")}</LinkLang>
              </>
          }
        </div>

        <OverlayTrigger placement="bottom" overlay={renderCloseTooltip}>
          <div role="button" className={"remove_icon_1 close_icon_left_nav_menu" + closeBtnClass} onClick={props.closeLeftNav}><i className="fa fa-times" aria-hidden="true" /></div>
        </OverlayTrigger>
      </Card.Header>

      <Card.Body>
        <ListGroup className="list-group-style1">
          <ListGroup.Item>
            <Form onSubmit={handleSubmitSearch}>
              <InputGroup>
                <FormControl
                  placeholder={t("searchProductText")}
                  aria-label={t("searchProductText")}
                  aria-describedby="button-header-search-mobile"
                  value={searchLeft}
                  onChange={handleChangeSearchLeft}
                  required
                />
                <InputGroup.Append>
                  <Button type="submit" variant="primary" id="button-header-search-mobile">
                    <i className="fa fa-fw fa-search" aria-hidden="true" />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </ListGroup.Item>
        </ListGroup>

        <ListGroup id="left_nav_menu_list_main" className="list-group-style1 menu_list">
          {/* <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(1, 0)}>
            <div className="float-left">
              Kategori Produk
            </div>
            <div className="float-right">
              <i className="fa fa-fw fa-list-ul" aria-hidden="true" />
            </div>
          </ListGroup.Item> */}
          <LinkLang className={"list-group-item group" + checkActiveMenu(0)} to="/product/category" onClick={props.closeLeftNav}>
            <div className="float-left">
              {t("productText")}
            </div>
            <div className="float-right">
              <i className="fa fa-fw fa-list-ul" aria-hidden="true" />
            </div>
          </LinkLang>
          {
            props.userData.isLoggedIn ?
              <>
                <LinkLang className={"list-group-item group" + checkActiveMenu(2)} to="/account/order" onClick={props.closeLeftNav}>
                  <div className="float-left">
                    <Text tid="orderText" isPlural={true} />
                    {
                      props.orderCount > 0 ?
                        <>
                          &nbsp;&nbsp;
                          <span className="badge badge-pill badge-danger">{props.orderCount}</span>
                        </>
                        :
                        null
                    }
                  </div>
                  <div className="float-right">
                    <i className="fa fa-fw fa-credit-card-alt" aria-hidden="true" />
                  </div>
                </LinkLang>
                <LinkLang className={"list-group-item group" + checkActiveMenu(3)} to="/account/favourite" onClick={props.closeLeftNav}>
                  <div className="float-left">
                    {t("menuFavourite")}
                  </div>
                  <div className="float-right">
                    <i className="fa fa-fw fa-heart" aria-hidden="true" />
                  </div>
                </LinkLang>
                {/* <LinkLang className={"list-group-item group" + checkActiveMenu(4)} to="/account/return" onClick={props.closeLeftNav}>
                  <div className="float-left">
                    {t("returnText")}
                  </div>
                  <div className="float-right">
                    <i className="fa fa-fw fa-retweet" aria-hidden="true" />
                  </div>
                </LinkLang>
                <LinkLang className={"list-group-item group" + checkActiveMenu(5)} to="/account/voucher" onClick={props.closeLeftNav}>
                  <div className="float-left">
                    {t("voucherText")}
                  </div>
                  <div className="float-right">
                    <i className="fa fa-fw fa-ticket" aria-hidden="true" />
                  </div>
                </LinkLang> */}
                <LinkLang className={"list-group-item group" + checkActiveMenu(6)} to="/account/setting" onClick={props.closeLeftNav}>
                  <div className="float-left">
                    {t("settingText")}
                  </div>
                  <div className="float-right">
                    <i className="fa fa-fw fa-wrench" aria-hidden="true" />
                  </div>
                </LinkLang>
              </>
              :
              null
          }
          <LinkLang className={"list-group-item group" + checkActiveMenu(7)} to="/help" onClick={props.closeLeftNav}>
            <div className="float-left">
              {t("helpText")}
            </div>
            <div className="float-right">
              <i className="fa fa-fw fa-info-circle" aria-hidden="true" />
            </div>
          </LinkLang>
          <LinkLang className={"list-group-item group" + checkActiveMenu(8)} to="/contact-us" onClick={props.closeLeftNav}>
            <div className="float-left">
              {t("contactUsText")}
            </div>
            <div className="float-right">
              <i className="fa fa-fw fa-phone" aria-hidden="true" />
            </div>
          </LinkLang>
          {
            props.userData.isLoggedIn ?
              <a role="button" onClick={() => { props.logout(); props.closeLeftNav(); }} className="list-group-item group">
                <div className="float-left">
                  {t("logoutText")}
                </div>
                <div className="float-right">
                  <i className="fa fa-fw fa-sign-out" aria-hidden="true" />
                </div>
              </a>
              :
              null
          }
        </ListGroup>

        {/* <ListGroup id="left_nav_menu_list_cat_lv1" className="list-group-style1 menu_list">
          <ListGroup.Item className="group">
            <LinkLang to=""><strong>Lihat Semua Produk</strong></LinkLang>
            <div className="left_nav_cat_back text-center" role="button" onClick={() => loadLeftNavBack(0)}>
              <i className="fa fa-fw fa-arrow-left text-primary" aria-hidden="true" />
              <br />
              <small>Back</small>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 1)}>
            <div className="float-left">
              Ayam
                </div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 1)}>
            <div className="float-left">Fashion</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 2)}>
            <div className="float-left">Handphone dan Tablet</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 3)}>
            <div className="float-left">Komputer</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 4)}>
            <div className="float-left">Kamera</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 5)}>
            <div className="float-left">Kecantikan dan Kesehatan</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 6)}>
            <div className="float-left">Perlengkapan Rumah</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <LinkLang className="list-group-item" to="">Ibu dan Anak</LinkLang>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 7)}>
            <div className="float-left">Buku</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 8)}>
            <div className="float-left">Hobi</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 9)}>
            <div className="float-left">Makanan Minuman dan lain lain dan sebagainya dan seterusnya</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(2, 10)}>
            <div className="float-left">Otomotif</div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
          <LinkLang className="list-group-item" to="">Olahraga</LinkLang>
        </ListGroup>

        <ListGroup id="left_nav_menu_list_cat_lv2" className="list-group-style1 menu_list">
          <ListGroup.Item className="group">
            <LinkLang to=""><strong>Lihat Semua Produk - Ayam</strong></LinkLang>
            <div className="left_nav_cat_back text-center" role="button" onClick={() => loadLeftNavBack(1)}>
              <i className="fa fa-fw fa-arrow-left text-primary" aria-hidden="true" />
              <br />
              <small>Back</small>
            </div>
          </ListGroup.Item>
          <ListGroup.Item className="group" role="button" onClick={() => loadLeftNavCat(3, 1)}>
            <div className="float-left">
              Bumbu
                </div>
            <div className="float-right caret_button collapsed"></div>
          </ListGroup.Item>
        </ListGroup>

        <ListGroup id="left_nav_menu_list_cat_lv3" className="list-group-style1 menu_list">
          <ListGroup.Item className="list-group-item group">
            <LinkLang to=""><strong>Lihat Semua Produk - Bumbu</strong></LinkLang>
            <div className="left_nav_cat_back text-center" role="button" onClick={() => loadLeftNavBack(2)}>
              <i className="fa fa-fw fa-arrow-left text-primary" aria-hidden="true" />
              <br />
              <small>Back</small>
            </div>
          </ListGroup.Item>
          <LinkLang className="list-group-item" to="#">Capung</LinkLang>
        </ListGroup> */}
      </Card.Body>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, ownProps, { userData: state.rootReducer.userData, orderCount: state.rootReducer.orderCount });
};

export default connect(mapStateToProps)(LeftNav);