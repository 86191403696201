import { id_id } from "./lang/id-id.js";
import { en_gb } from "./lang/en-gb.js";

export const translations =
{
  en: {
    messages: { ...en_gb }
  },
  id: {
    messages: { ...id_id }
  }
};