import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { IntlActions } from 'react-redux-multilingual';
import { translations } from './languages/translations.js';
import { getOrderCountWithSaga } from "./redux/actions/index.js";
import * as CONFIG from './config.js';

function getLocaleFromPath(path) {
  if (path === "/") {
    if (localStorage.getItem("locale")) return localStorage.getItem("locale");
    else return CONFIG.DEFAULT_LOCALE;
  }
  else {
    const pathLocale = path.split('/')[1];
    if (translations.hasOwnProperty(pathLocale)) return pathLocale;
    else {
      if (localStorage.getItem("locale")) return localStorage.getItem("locale");
      else return CONFIG.DEFAULT_LOCALE;
    }
  }
}

class Localizer extends React.Component {
  constructor(props) {
    super(props)

    this.setLocale(getLocaleFromPath(this.props.location.pathname));
    this.props.history.listen(location => {
      this.setLocale(getLocaleFromPath(location.pathname));
    })
  }
  setLocale(newLocale) {
    if (newLocale !== this.props.locale) {
      this.props.changeLocale(newLocale);
      if (!localStorage.getItem("locale") || localStorage.getItem("locale") !== newLocale) localStorage.setItem("locale", newLocale);
    }
    if (this.props.userData.isLoggedIn && this.props.userData.status === 1) this.props.getOrderCount(CONFIG.BACK_END_URL + "/api/order/count/unfinish");
  }
  render() {
    if (getLocaleFromPath(this.props.location.pathname) === this.props.locale)
      return this.props.children
    else
      return null;
  }
}

function mapStateToProps(state) {
  return { ...state.Intl, userData: state.rootReducer.userData };
};

function mapDispatchToProps(dispatch) {
  return {
    changeLocale: locale => dispatch(IntlActions.setLocale(locale)),
    getOrderCount: url => dispatch(getOrderCountWithSaga(url))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Localizer)
);