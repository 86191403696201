import {getCookie, isEmptyObject} from './utilities.js';

export const fetchHandler = async (type, fetchUrl, withCredential, bodyData) => {
  let headerVar = {
    'Accept': 'application/json'
  };
  if (withCredential) headerVar['X-XSRF-TOKEN'] = getCookie("XSRF-TOKEN");

  let fetchObject = {
    method: type,
    mode: "cors"
  };
  if (withCredential) fetchObject.credentials = 'include';

  if (!isEmptyObject(headerVar)){
    fetchObject.headers = headerVar;
  }
  if (type === "POST") {
    if (typeof bodyData !== 'undefined' && bodyData !== undefined && bodyData !== null && bodyData !== '' && bodyData) fetchObject.body = bodyData;
  }

  if (isEmptyObject(fetchObject))
    return fetch(fetchUrl);
  else
    return fetch(fetchUrl, fetchObject);
};