import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/index.js";
import { IntlReducer as Intl } from "react-redux-multilingual";

import createSagaMiddleware from "redux-saga";
import apiSaga from "../sagas/index.js";

let reducers = combineReducers(Object.assign({}, { rootReducer }, { Intl }));
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialiseSagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducers,
  storeEnhancers(
    applyMiddleware(initialiseSagaMiddleware)
  )
);
initialiseSagaMiddleware.run(apiSaga);

export default store;