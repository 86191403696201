import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-redux-multilingual';

function LinkLang(props) {
  const t = useTranslate();
  let prevProps = Object.assign({}, props);
  delete prevProps.to;
  delete prevProps.children;
  let dest = props.to;
  if(dest === "/") dest = "";
  return <Link {...prevProps} to={"/" + t("lang") + dest}>{props.children}</Link>;
}

export default LinkLang;