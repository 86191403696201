import * as ACTIONS from "../constants/action-types.js";
import { listQtyPrice } from '../../scripts/cartHelper.js';
import { isEmptyArray, isEmptyObject } from "../../scripts/utilities.js";

const initialState = {
  userData: {},
  cartData: {
    isGet: false,
    cart: [],
    product_discount: [],
    product_option: [],
    product_option_value: [],
    listQty: [],
    listPrice: [],
    itemCount: 0
  },
  orderCount: 0
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_USER_DATA:
      return Object.assign({}, state, { userData: Object.assign({}, action.payload) });
    case ACTIONS.SET_CART_DATA:
      let newState = Object.assign({}, action.payload);
      let isGet = true;
      if (!isEmptyObject(newState)) {
        if (!isEmptyArray(newState.cart)) {
          const listQtyPriceVal = listQtyPrice(newState);
          newState["listQty"] = listQtyPriceVal[0];
          newState["listPrice"] = listQtyPriceVal[1];
          newState["itemCount"] = listQtyPriceVal[2];
          newState["isGet"] = isGet;
          return Object.assign({}, state, { cartData: Object.assign({}, newState) });
        }
        else isGet = false;
      }

      return Object.assign({}, state,
        {
          cartData: {
            isGet: isGet,
            cart: [],
            product_discount: [],
            product_option: [],
            product_option_value: [],
            listQty: [],
            listPrice: [],
            itemCount: 0
          }
        });
    case ACTIONS.SET_ORDER_COUNT:
      let order_count = 0;
      if (action.payload.order_count) order_count = action.payload.order_count;
      return Object.assign({}, state, { orderCount: order_count });
    default:
      break;
  }
  return state;
};

export default rootReducer;