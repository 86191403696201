import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from 'react-redux-multilingual';

/* import Button from 'react-bootstrap/Button'; */
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
/* import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup'; */
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import LinkLang from './LinkLang.js';
import { renderBackToTopTooltip } from './Tooltips.js';

import { fetchHandler } from '../scripts/fetchHandler.js';
import { backToTop, replaceHTMLEntities } from '../scripts/utilities.js';
import * as CONFIG from '../config.js';

import '../styles/footer.css';

function Footer(props) {
  const tanggal = new Date();
  const [footerData, setFooterData] = useState({
    owner_name: "",
    footer_infos: []
  });

  const t = useTranslate();
  const langId = t("id");

  useEffect(() => {
    fetchHandler(
      "GET",
      CONFIG.BACK_END_URL + "/api/footer/" + langId,
      true
    )
      .then(response => {
        if (response.status === 200) {
          response.json().then(function (responseData) {
            setFooterData(responseData);
          });
        }
      });
  }, [langId]);

  let infoLinks = [], idx = footerData.footer_infos.length, linkHref = "";
  while (idx--) {
    linkHref = "/page?information_id=" + footerData.footer_infos[idx].information_id;
    if (footerData.footer_infos[idx].seo_url) linkHref = "/page/" + footerData.footer_infos[idx].seo_url;
    infoLinks.push(
      <ListGroup.Item key={"footerInfoLinks" + idx}>
        <LinkLang to={linkHref}>{replaceHTMLEntities(footerData.footer_infos[idx].title)}</LinkLang>
      </ListGroup.Item>
    );
  }
  return (
    <>
      <div id="div_footer_md" className="margin_top_30">
        <div className="background_color_2">
          <Container>
            <Row>
              <Col xs={12} lg={4} className="background_color_4">
                <ListGroup className="padding_top_10 padding_bottom_10">
                  {/* <ListGroup.Item className="d-flex background_color_4">
                    <div className="w-10">
                      <i className="fa fa-fw fa-thumbs-o-up text-primary feature_icon" aria-hidden="true"/>
                    </div>
                    <div className="w-90 pl-3">
                      <div className="d-flex mb-1 justify-content-between">
                        <h6>Aman Terpercaya</h6>
                      </div>
                      <p>
                        Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus.
                      </p>
                    </div>
                  </ListGroup.Item> */}
                  <ListGroup.Item className="d-flex background_color_4">
                  <div className="w-10">
                      <i className="fa fa-fw fa-trophy text-primary feature_icon" aria-hidden="true" />
                    </div>
                    <div className="w-90 pl-3">
                      <div className="d-flex mb-1 justify-content-between">
                        <h6>{t("footerPoint3")}</h6>
                      </div>
                      <p className="mb-0">{t("footerPoint3Desc")}</p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex background_color_4">
                    <div className="w-10">
                      <i className="fa fa-fw fa-percent text-primary feature_icon" aria-hidden="true" />
                    </div>
                    <div className="w-90 pl-3">
                      <div className="d-flex mb-1 justify-content-between">
                        <h6>{t("footerPoint2")}</h6>
                      </div>
                      <p className="mb-0">{t("footerPoint2Desc")}</p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex background_color_4">
                  <div className="w-10">
                      <i className="fa fa-fw fa-credit-card-alt text-primary feature_icon" aria-hidden="true" />
                    </div>
                    <div className="w-90 pl-3">
                      <div className="d-flex mb-1 justify-content-between">
                        <h6>{t("footerPoint1")}</h6>
                      </div>
                      <p className="mb-0">{t("footerPoint1Desc")}</p>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col xs={12} lg={8}>
                <div className="container_footer_content_1">
                  <Row>
                    <Col xs={12} sm={6} md={4}>
                      <span className="text_size_24">{t("informationText")}</span>
                      <div className="footer_divider"></div>
                      <ListGroup className="list-group-style2">
                        {infoLinks}
                      </ListGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <span className="text_size_24">{t("helpText")}</span>
                      <div className="footer_divider"></div>
                      <ListGroup className="list-group-style2">
                        <ListGroup.Item><LinkLang to="/contact-us">{t("contactUsText")}</LinkLang></ListGroup.Item>
                        <ListGroup.Item><LinkLang to="/help">{t("helpText")}</LinkLang></ListGroup.Item>
                      </ListGroup>
                    </Col>

                    <div className="clearfix d-md-none"></div>
                    {
                      props.isLoggedIn ?
                        <Col xs={12} sm={6} md={4}>
                          <span className="text_size_24">{t("myAccountText")}</span>
                          <div className="footer_divider"></div>
                          <ListGroup className="list-group-style2">
                            <ListGroup.Item><LinkLang to="/account">{t("myAccountText")}</LinkLang></ListGroup.Item>
                            <ListGroup.Item><LinkLang to="/account/order">{t("orderText")}</LinkLang></ListGroup.Item>
                            <ListGroup.Item><LinkLang to="/account/favourite">{t("menuFavourite")}</LinkLang></ListGroup.Item>
                            <ListGroup.Item><LinkLang to="/account/setting">{t("settingText")}</LinkLang></ListGroup.Item>
                          </ListGroup>
                        </Col>
                        :
                        <Col xs={12} sm={6} md={4}>
                          <span className="text_size_24">{CONFIG.STORE_NAME}</span>
                          <div className="footer_divider"></div>
                          <ListGroup className="list-group-style2">
                            <ListGroup.Item><LinkLang to="/product/category">{t("productText")}</LinkLang></ListGroup.Item>
                            <ListGroup.Item><LinkLang to="/login">{t("loginText")}</LinkLang></ListGroup.Item>
                            <ListGroup.Item><LinkLang to="/account/register">{t("registerText")}</LinkLang></ListGroup.Item>
                          </ListGroup>
                        </Col>
                    }
                  </Row>
                </div>
                {/* <div className="container_footer_content_2">
                  <Row>
                    <Col xs={12}>
                      <span className="text_size_24">Newsletter</span>
                      <div className="footer_divider"></div>
                      <div className="spacer_4"></div>
                      <Row>
                        <Col xs={12} md={6} lg={8}>
                          Dengan berlangganan ke newsletter Kami, Anda dapat dengan cepat mengetahui informasi penawaran dan promosi terbaru dari Prisma.
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                          <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="email"
                              placeholder="masukan e-mail anda" aria-label="email-subscription" aria-describedby="basic-addon1" />
                          </InputGroup>
                          <Button variant="primary" className="w-100">Berlangganan</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="background_color_1">
          <Container>
            <Row>
              <Col xs={12}>
                <OverlayTrigger placement="top" overlay={renderBackToTopTooltip}>
                  <div role="button" onClick={backToTop} className="div_valign_middle footer_colored_bar_button background_color_3">
                    <div className="text-center">
                      <i className="fa fa-fw fa-chevron-circle-up" aria-hidden="true" />
                    </div>
                  </div>
                </OverlayTrigger>
                <a href="https://www.linkedin.com/company/prismaklik" target="_blank" rel="noopener noreferrer nofollow" className="div_valign_middle footer_colored_bar_button">
                  <div className="text-center">
                    <i className="fa fa-fw fa-linkedin" aria-hidden="true" />
                  </div>
                </a>
                <a href="https://www.facebook.com/PrismaKlik" target="_blank" rel="noopener noreferrer nofollow" className="div_valign_middle footer_colored_bar_button">
                  <div className="text-center">
                    <i className="fa fa-fw fa-facebook" aria-hidden="true" />
                  </div>
                </a>
                <a href="https://www.instagram.com/prismaklik" target="_blank" rel="noopener noreferrer nofollow" className="div_valign_middle footer_colored_bar_button">
                  <div className="text-center">
                    <i className="fa fa-fw fa-instagram" aria-hidden="true" />
                  </div>
                </a>
                <a href="https://wa.me/6285156856342" target="_blank" rel="noopener noreferrer nofollow" className="div_valign_middle footer_colored_bar_button">
                  <div className="text-center">
                    <i className="fa fa-fw fa-whatsapp" aria-hidden="true" />
                  </div>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="background_color_2">
          <Container>
            <Row>
              <Col xs={6} md={4} className="background_color_4">
                <ListGroup>
                  <ListGroup.Item className="background_color_4">
                    <Table cellSpacing="0" cellPadding="0" className="table table_no_left_right_padding table_footer_ssl">
                      <tbody>
                        <tr>
                          <td className="d-none d-lg-table-cell">
                            <em className="text_secured_by">{t("securedBy").toUpperCase()}&nbsp;:</em>
                          </td>
                          <td>
                            <span className="text_secured_by d-lg-none">{t("securedBy").toUpperCase()}:<br /></span>
                            <a href="https://letsencrypt.org/" target="_blank" rel="noopener noreferrer nofollow" className="a_non_text">
                              <img src={CONFIG.IMAGE_BASE_URL + "catalog/lets-encrypt-seal.png"} alt="Let's Encrypt SSL" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col xs={6} md={8} className="group">
                <div className="div_valign_middle footer_copyright">
                  <div>
                    <span>&copy;&nbsp;{tanggal.getFullYear()}</span>
                    <div className="clearfix d-sm-none"></div>
                    <span>&nbsp;{footerData.owner_name}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return { isLoggedIn: state.rootReducer.userData.isLoggedIn };
};

export default connect(mapStateToProps)(Footer);