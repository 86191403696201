import * as ACTIONS from "../constants/action-types.js";

export function setUserData(payload) {
  return { type: ACTIONS.SET_USER_DATA, payload }
};

export function setCartData(payload) {
  return { type: ACTIONS.SET_CART_DATA, payload }
};

export function getCartDataWithSaga(url) {
  return { type: ACTIONS.GET_CART_DATA, payload: { url } };
}

export function getOrderCountWithSaga(url) {
  return { type: ACTIONS.GET_ORDER_COUNT, payload: { url } };
}