import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

function SnackBarToast(props) {
  if (props.isOpen && props.message && props.message !== '') {
    let action = null;
    if(props.action) action = props.action;
    if (props.autoHide > 0)
      return (
        <Snackbar
          open={props.isOpen}
          message={props.message}
          autoHideDuration={props.autoHide}
          onClose={props.closeSnackBar}
          action={action}
        />
      );
    else
      return (
        <Snackbar
          open={props.isOpen}
          message={props.message}
          action={
            <>
              {action}
              <IconButton size="small" aria-label="close" color="inherit" onClick={props.closeSnackBar}>
                <span className="snackbar_close_icon">&times;</span>
              </IconButton>
            </>
          }
        />
      );
  }
  else return null;
}

export default SnackBarToast;