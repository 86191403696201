import { takeEvery, call, put } from "redux-saga/effects";
import { fetchHandler } from '../../scripts/fetchHandler.js';
import * as ACTIONS from "../constants/action-types.js";

function getData(url) {
  return fetchHandler("GET", url, true)
    .then(response =>
      response.json()
    );
}
function* workerGetCartData(action) {
  try {
    const payload = yield call(getData, action.payload.url);
    yield put({ type: ACTIONS.SET_CART_DATA, payload });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
function* workerGetOrderCount(action) {
  try {
    const payload = yield call(getData, action.payload.url);
    yield put({ type: ACTIONS.SET_ORDER_COUNT, payload });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
export default function* watcherSaga() {
  yield takeEvery(ACTIONS.GET_CART_DATA, workerGetCartData);
  yield takeEvery(ACTIONS.GET_ORDER_COUNT, workerGetOrderCount);
}