import React from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { isEmptyObject } from '../scripts/utilities';

export default function Text(props) {
  const t = useTranslate();
  let fillData = {};
  if(!isEmptyObject(props.fillData)) fillData = Object.assign({}, props.fillData);
  let returnText = t(props.tid, fillData);
  if (typeof props.isPlural !== "undefined" && props.isPlural !== undefined && props.isPlural !== null && props.isPlural)
    returnText += t("pluralSuffix");
  return <>{returnText}</>;
}